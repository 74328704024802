/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback } from 'react';
import AsyncLock from 'async-lock';

import { AuthContextType, UserProfile } from 'types/auth';
import axios from 'axios';
import { queryClient } from 'App';
import { useDispatch, useSelector } from 'store';
import dayjs from 'dayjs';
import { setLogin, setLogout } from 'store/reducers/auth';

// ISTANZA DI AXIOS PER L'ENDOPOINT AUTH
const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + 'server/' + process.env.REACT_APP_API_VERSION + '/auth'
});

type AxiosAuthResponseType = {
  accessToken: string;
  refreshToken: string;
  accessExpireAt: string;
  user: UserProfile;
};

const lock = new AsyncLock();
const REFRESH_LOCK = 'REFRESH_LOCK';
const LOGOUT_LOCK = 'LOGOUT_LOCK';

// ==============================|| AUTHENTICATION CONTEXT & PROVIDER ||============================== //

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const dispatch = useDispatch();
  const { rememberMe } = useSelector((state) => state.authorization);

  const authEmailPasswordSignIn = useCallback(
    (email: string, password: string) =>
      axiosAuth<AxiosAuthResponseType>({
        method: 'post',
        url: '/welcome',
        data: { param1: email, param2: password }
      }).then((res) => {
        // setUser(res.data.user);

        localStorage.setItem('stayActive', rememberMe.toString());

        if (localStorage.getItem('stayActive') === 'true') {
          localStorage.setItem('accessToken', res.data.accessToken);
          localStorage.setItem('refreshToken', res.data.refreshToken);
        } else {
          sessionStorage.setItem('accessToken', res.data.accessToken);
          sessionStorage.setItem('refreshToken', res.data.refreshToken);
        }

        const user = res.data.user;
        dispatch(
          setLogin({
            isLoggedIn: true,
            id: user.id,
            email: user.email,
            phone: user.phone,
            firstname: user.firstname,
            lastname: user.lastname,
            permissions: user.permissions,
            imageUploadCode: user.imageUploadCode,
            lastEdit: user.lastEdit,
            super: user.super
          })
        );
        localStorage['accessExpireAt'] = res.data.accessExpireAt;
      }),
    [rememberMe]
  );

  const authTokenLogin = useCallback(
    () =>
      axiosAuth<AxiosAuthResponseType>({
        method: 'get',
        url: '/me',
        headers: {
          Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}`
        }
      })
        .then((res) => {
          // setUser(res.data);
          const user = res.data.user;
          dispatch(
            setLogin({
              isLoggedIn: true,
              id: user.id,
              email: user.email,
              phone: user.phone,
              firstname: user.firstname,
              lastname: user.lastname,
              permissions: user.permissions,
              imageUploadCode: user.imageUploadCode,
              lastEdit: user.lastEdit,
              super: user.super
            })
          );
        })
        .catch(async (error) => {
          if (error.response?.data?.message === 'invalid_token') {
            localStorage.removeItem('accessExpireAt');
            await refreshToken();
          } else {
            await logout();
          }
        }),
    [rememberMe, localStorage['accessToken'], sessionStorage['accessToken']]
  );

  const refreshToken = useCallback(async () => {
    return lock.acquire(REFRESH_LOCK, async () => {
      if (localStorage['accessExpireAt'] && dayjs().isBefore(localStorage['accessExpireAt'])) return;
      await axiosAuth<AxiosAuthResponseType>({
        method: 'post',
        url: '/refresh',
        headers: { Authorization: `Bearer ${rememberMe ? localStorage['refreshToken'] : sessionStorage['refreshToken']}` }
      })
        .then(({ data }) => {
          // setUser(data.user);
          const user = data.user;
          dispatch(
            setLogin({
              isLoggedIn: true,
              id: user.id,
              email: user.email,
              phone: user.phone,
              firstname: user.firstname,
              lastname: user.lastname,
              permissions: user.permissions,
              imageUploadCode: user.imageUploadCode,
              lastEdit: user.lastEdit,
              super: user.super
            })
          );
          localStorage['accessExpireAt'] = data.accessExpireAt;

          if (rememberMe) {
            localStorage['accessToken'] = data.accessToken;
            localStorage['refreshToken'] = data.refreshToken;
          } else {
            sessionStorage['accessToken'] = data.accessToken;
            sessionStorage['refreshToken'] = data.refreshToken;
          }
        })
        .catch(async () => {
          await logout();
        });

      return;
    });
  }, [rememberMe, localStorage['refreshToken'], sessionStorage['refreshToken']]);

  const logout = useCallback(async () => {
    return lock.acquire(LOGOUT_LOCK, async () => {
      if (localStorage['accessToken'] === '' && sessionStorage['accessToken'] === '') return;
      await axiosAuth<{ result: string }>({
        method: 'post',
        url: '/logout',
        headers: { Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}` }
      }).finally(() => {
        localStorage['accessToken'] = '';
        localStorage['refreshToken'] = '';
        sessionStorage['refreshToken'] = '';
        sessionStorage['accessToken'] = '';
        localStorage['accessExpireAt'] = '';

        dispatch(setLogout());
        queryClient.clear();
      });
    });
  }, [rememberMe, localStorage['accessToken'], sessionStorage['accessToken']]);

  const forgotPassword = useCallback(
    (email: string) =>
      axiosAuth({
        method: 'post',
        url: '/password-reset',
        data: {
          email
        }
      }),
    []
  );

  const resetPassword = useCallback(
    (password: string, newPassword: string, code: string) =>
      axiosAuth({
        method: 'put',
        url: `/password-reset/${code}`,
        data: {
          password,
          passwordConfirm: newPassword
        }
      }),
    []
  );

  const editPassword = useCallback(
    (oldPassword: string, newPassword: string, confirmNewPassword: string) =>
      axiosAuth({
        method: 'patch',
        url: '/password',
        headers: { Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}` },
        data: {
          oldPassword,
          newPassword,
          confirmNewPassword
        }
      }),
    [rememberMe, localStorage['accessToken'], sessionStorage['accessToken']]
  );

  return (
    <AuthContext.Provider
      value={{
        refreshToken,
        authEmailPasswordSignIn,
        authTokenLogin,
        logout,
        forgotPassword,
        resetPassword,
        editPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
