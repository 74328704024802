import { Search } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

interface Props {
  handleSetFilter: (searchFilter: string) => void;
  width?: number | string;
}
const SearchBar = ({ handleSetFilter, width }: Props) => {
  const [waitFilterTimeout, setWaitFilterTimeout] = useState<NodeJS.Timeout | null>(null);

  const searchForm = useFormik<{ searchFilter?: string }>({
    initialValues: {
      searchFilter: ''
    },
    onSubmit: async (values) => {
      await handleSetFilter(values.searchFilter!);
    }
  });

  useEffect(() => {
    if (searchForm.values.searchFilter !== undefined) {
      if (waitFilterTimeout) {
        clearTimeout(waitFilterTimeout);
      }
      const timeout = setTimeout(() => {
        setWaitFilterTimeout(null);
        searchForm.handleSubmit();
      }, 700);

      setWaitFilterTimeout(timeout);
    }
  }, [searchForm.values.searchFilter]);

  return (
    <TextField
      sx={{ width: width ?? '100%' }}
      size="small"
      placeholder="Cerca"
      autoCorrect="false"
      name="searchFilter"
      value={searchForm.values.searchFilter}
      onChange={searchForm.handleChange}
      InputProps={{
        endAdornment: (
          <IconButton type="button" aria-label="search">
            <Search />
          </IconButton>
        )
      }}
    />
  );
};

export default SearchBar;
