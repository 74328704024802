import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { dispatch, useSelector } from 'store';
import { setInizialized } from '../../store/reducers/auth';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { authTokenLogin } = useAuth();
  const { isLoggedIn, isInitialized } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !isLoggedIn &&
      (localStorage['accessToken'] || sessionStorage['accessToken']) &&
      ((localStorage['accessToken'] !== '' && localStorage['accessToken'] !== undefined) ||
        (sessionStorage['accessToken'] !== '' && sessionStorage['accessToken'] !== undefined))
    ) {
      console.log('me sloggato GUEST');
      authTokenLogin();
    } else if (
      isLoggedIn &&
      (localStorage['accessToken'] || sessionStorage['accessToken']) &&
      ((localStorage['accessToken'] !== '' && localStorage['accessToken'] !== undefined) ||
        (sessionStorage['accessToken'] !== '' && sessionStorage['accessToken'] !== undefined))
    ) {
      navigate('/dashboard', { replace: true });
    } else {
      dispatch(setInizialized());
    }
  }, [isLoggedIn, navigate]);

  return isInitialized && !isLoggedIn ? children : <></>;
};

export default GuestGuard;
