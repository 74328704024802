// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TOOLTIP ||============================== //

export default function Tooltip(theme: Theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: theme.palette.primary.main
        },
        tooltip: {
          backgroundColor: theme.palette.primary.main,
          opacity: 1
          // width: 2000
        }
      }
    }
  };
}
