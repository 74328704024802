import { Switch, Typography } from '@mui/material';
import { Box, FormControlLabel } from '@mui/material';
import { CustomTooltip } from 'components/custom/CustomTooltip';
import DateSelector from 'components/custom/dates/DateSelector';
import { FormikErrors } from 'formik';
import { SendTaskDTO } from 'types/dto/task.dto';

interface Props {
  taskForm?: SendTaskDTO;
  setValues: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          data: SendTaskDTO | undefined;
        }>
      >;
  disabled: boolean;
}
export const ExpireDateAndPrivacy = ({ taskForm, setValues, disabled }: Props) => {
  return (
    <Box className="flex just-btwn ali-center" mt={1}>
      <DateSelector
        label="Data di scadenza"
        ISODate={taskForm?.expireAt}
        setISODate={setValues}
        formikField="data.expireAt"
        placeholder="Nessuna"
      />
      <CustomTooltip content={`Imposta la visibilità del task a ${taskForm?.public ? 'Privato' : 'Pubblico'}`}>
        <span>
          <FormControlLabel
            control={
              <Switch
                checked={!taskForm?.public}
                disabled={disabled}
                onChange={(e) => setValues('data.public', !e.currentTarget.checked)}
              />
            }
            label={
              <Typography color="text.secondary" variant="body2">
                Privato
              </Typography>
            }
            labelPlacement="top"
          />
        </span>
      </CustomTooltip>
    </Box>
  );
};
