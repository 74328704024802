import { useState, useEffect } from 'react';

const useLoader = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [timeoutFinish, setTimeoutFinish] = useState(false);

  useEffect(() => {
    timeoutFinish && setIsLoading(false);
  }, [timeoutFinish]);

  const timeout = () => {
    let min = setTimeout(() => {
      setTimeoutFinish(true);
    }, 230);

    return () => clearTimeout(min);
  };
  useEffect(() => {
    timeout();
  }, []);

  return { isLoading };
};

export default useLoader;
