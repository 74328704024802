// material-ui
import { SvgIcon, SvgIconTypeMap } from '@mui/material';
// ==============================|| AUTH BLUR BACK SVG ||============================== //

const ContractsLogo = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="22" height="29" viewBox="0 0 22 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9996 29H0V0H19.057C20.6825 0 22 1.30251 22 2.90952V28.9996L21.9996 29Z" fill="#094754" />
      <path d="M18 9H9V12H18V9Z" fill="#91DCF9" />
      <path d="M18 20H4V23H18V20Z" fill="#91DCF9" />
      <path d="M18 15H4V17H18V15Z" fill="#91DCF9" />
      <path d="M22 5V2.56473C22 1.14836 20.8516 0 19.4353 0H17L22 5Z" fill="#91DCF9" />
      <path d="M17 5H22L17 0V5Z" fill="#1ED367" />
      <path
        d="M5.5 12C6.32843 12 7 11.3284 7 10.5C7 9.67157 6.32843 9 5.5 9C4.67157 9 4 9.67157 4 10.5C4 11.3284 4.67157 12 5.5 12Z"
        fill="#F0FF32"
      />
    </svg>
  </SvgIcon>
);

export default ContractsLogo;
