import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { queryClient } from 'App';
import { ColoredInstantSelectStatus } from 'components/custom/status/select/ColoredInstantSelectStatus';
import { taskStatusList } from 'components/custom/status/statusList/task';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useEndpoint } from 'hooks/useEndpoint';
import { dispatch } from 'store';
import { setActiveRightItem, setTaskId } from 'store/reducers/menu';
import { openSnackbar } from 'store/reducers/snackbar';
import { SendTaskDTO, TaskDTO } from 'types/dto/task.dto';
import { avatarInitials, humanizeDate } from 'utils/formattation';

interface Props {
  task: TaskDTO;
  sorting: string;
}

const SingleTask = ({ task, sorting }: Props) => {
  const theme = useTheme();

  const editTask = useEndpoint<Partial<SendTaskDTO>, 'patch'>({
    method: 'patch',
    endpoint: `/tasks/${task.id}`,
    mutationKey: `change-state-on-task-${task.id}`,
    options: {
      onSuccess: () => {
        dispatch(
          openSnackbar({
            message: `Stato modificato con successo.`,
            open: true,
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            key: `edit-task-${task.id}-status`
          })
        );
        queryClient.refetchQueries('get-all-tasks');
        queryClient.refetchQueries('get-tasks-readings');
      }
    }
  });

  const singleTaskForm = useFormik({
    enableReinitialize: true,
    initialValues: { state: task.state.key, lastEdit: task.lastEdit },
    onSubmit: (values) => editTask.mutate(values)
  });

  return (
    <Box sx={{ borderBottom: `1px solid ${theme.palette.divider}`, ':last-child': { borderBottom: 'none' } }}>
      <form onSubmit={singleTaskForm.handleSubmit}>
        <Box
          className="flex just-btwn clickable"
          zIndex={1}
          onClick={() => {
            dispatch(setTaskId(task.id));
            dispatch(setActiveRightItem('task'));
          }}
        >
          <Box className="flex ali-center">
            <Avatar sx={{ margin: 1, width: 36, height: 36 }}>{avatarInitials(`${task.user.firstname} ${task.user.lastname}`)}</Avatar>
            <Box ml={1}>
              <Typography variant="body2" mr={0.5}>
                {task.title}
              </Typography>
              <Typography variant="caption" color="text.secondary" mr={0.5}>
                {task?.resourceSlug
                  ? task?.resourceSlug === 'order'
                    ? task?.subject
                      ? `Ordine ${task.subject}`
                      : 'Ordini'
                    : task?.resourceSlug === 'user'
                    ? task?.extra?.user?.firstname && task?.extra?.user?.lastname
                      ? `Utente ${task.extra.user.firstname} ${task.extra.user.lastname}`
                      : 'Utenti'
                    : task.extra?.building?.name
                    ? `Struttura ${task.extra?.building?.name}`
                    : 'Strutture'
                  : 'Task generale'}
              </Typography>
            </Box>
          </Box>
          <Box className="flex ali-center">
            <ColoredInstantSelectStatus
              chipSize="small"
              initialStatus={task.state.key}
              sendData={({ state }) => {
                singleTaskForm.setFieldValue('state', state);
                singleTaskForm.handleSubmit();
              }}
              statusList={taskStatusList}
            />

            {task.lastReadAt === null || dayjs(task.lastReadAt).diff(task.lastEdit) < 0 ? (
              <Box sx={{ width: 8, height: 8, borderRadius: '100%', backgroundColor: `${theme.palette.error.main}`, margin: 1 }} />
            ) : (
              <Box sx={{ width: 8, height: 8, margin: 1 }} />
            )}
            {sorting.includes('lastEdit') && (
              <Typography variant="caption" marginRight={2} width={110} textAlign="right">
                {dayjs(task.lastEdit).format(`DD/MM/YYYY HH:mm`)}
              </Typography>
            )}
            {sorting.includes('createdAt') && (
              <Typography variant="caption" marginRight={2} width={110} textAlign="right">
                {dayjs(task.createdAt).format(`DD/MM/YYYY HH:mm`)}
              </Typography>
            )}
            {sorting.includes('expireAt') && (
              <Box className="flex dir-col ali-center">
                <Typography variant="caption" marginRight={2} width={110} textAlign="right">
                  {task?.expireAt ? dayjs(task.expireAt).format('DD/MM/YYYY') : 'Non scade'}
                </Typography>
                {task.expireAt && (
                  <Typography variant="caption" color="text.secondary" marginRight={2} width={110} textAlign="right">
                    {task?.expireAt ? humanizeDate(task.expireAt) : 'Non scade'}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default SingleTask;
