// import { Link } from 'react-router-dom';

// material-ui
import { Button, Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { APP_DEFAULT_PATH } from 'config';

// project import
// import config from 'config';
// assets

// ==============================|| UNDER CONSTRUCTION - MAIN ||============================== //

function Unauthorized() {
  return (
    <Grid container spacing={4} direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh', py: 2, px: 8 }}>
      <Grid item xs={12}>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Typography align="center" variant="h1">
            Non hai i permessi per visualizzare questa pagina
          </Typography>
          <Typography color="textSecondary" align="center" sx={{ width: '85%' }}>
            Chiedi ad un amministratore di aggiornare i tuoi permessi.
          </Typography>
        </Stack>
        <Stack mt={3} spacing={2} justifyContent="center" alignItems="center">
          <Button component={Link} to={APP_DEFAULT_PATH} variant="contained">
            Torna alla home
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Unauthorized;
