import { FormikErrors } from 'formik';
import { SendTaskDTO } from 'types/dto/task.dto';
import { AddComment } from './AddComment';
import Comments from './Comments';

interface Props {
  comments: { description: string }[] | undefined;
  setValues: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          data: SendTaskDTO | undefined;
        }>
      >;
}
export const NewTaskComments = ({ comments, setValues }: Props) => {
  return (
    <>
      <AddComment comments={comments ?? []} setValues={setValues} />
      <Comments comments={comments ?? []} setValues={setValues} />
    </>
  );
};
