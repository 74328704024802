import { Box } from '@mui/material';
import SearchBar from 'components/custom/SearchBar';
import { useFormik } from 'formik';
import { Subject } from 'types/components/subjectFilter';
import { SendNoteDTO } from 'types/dto/note.dto';
import { CreateNote } from './CreateNote';
import { useEndpoint } from 'hooks/useEndpoint';
import { queryClient } from 'App';

interface Props {
  section?: string | null;
  subject: Subject;
  setSearchFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
}
export const NoteAndSearch = ({ section, subject, setSearchFilter }: Props) => {
  const createNote = useEndpoint<SendNoteDTO, 'post'>({
    method: 'post',
    endpoint: '/notes',
    mutationKey: 'create-note',
    options: {
      onSuccess: () => {
        queryClient.refetchQueries('get-all-notes');
        newNoteForm.setFieldValue('message', '');
      }
    }
  });
  const newNoteForm = useFormik<SendNoteDTO>({
    enableReinitialize: true,
    initialValues: {
      message: '',
      resource: section ?? null,
      subject: subject.serverData,
      label: subject.label
    },
    onSubmit: (values) => {
      createNote.mutate(values);
    }
  });
  return (
    <Box className="vertical-standard-margin">
      <Box className="flex just-btwn ali-center">
        <SearchBar width="100%" handleSetFilter={(value) => setSearchFilter(value)} />
      </Box>
      <Box className="vertical-standard-margin">
        <CreateNote
          values={newNoteForm.values}
          handleChange={newNoteForm.handleChange}
          handleSubmit={newNoteForm.handleSubmit}
          enabled={Boolean(section || section === null) && Boolean(subject.serverData.length)}
        />
      </Box>
    </Box>
  );
};
