import { Typography } from '@mui/material';
import { AnimationBox } from './AnimationBox';

interface Props {
  label: string;
}
const Label = ({ label }: Props) => (
  <AnimationBox>
    <Typography variant="h5">{label}</Typography>
  </AnimationBox>
);

export default Label;
