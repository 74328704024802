import { SendTaskDTO } from 'types/dto/task.dto';
import { TaskComments } from './taskComments';
import { FormikErrors } from 'formik';
import { NewTaskComments } from './newTaskComments';

interface Props {
  isNewTask?: boolean;
  taskForm: SendTaskDTO;
  setValues: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          data: SendTaskDTO | undefined;
        }>
      >;
}
export const Comments = ({ isNewTask, taskForm, setValues }: Props) => {
  return (
    <>
      {isNewTask ? (
        <NewTaskComments comments={taskForm.comments} setValues={setValues} />
      ) : (
        taskForm.comments && <TaskComments taskID={taskForm.id!} comments={taskForm.comments} setValues={setValues} />
      )}
    </>
  );
};
