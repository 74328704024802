import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  ClickAwayListener,
  Grid,
  Grow,
  Popper,
  TextField,
  Typography,
  styled,
  useTheme,
  IconButton
} from '@mui/material';
import Transition from 'components/custom/Transition';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import useModal from 'hooks/useModal';
import { useState } from 'react';
import AsyncAutoComplete from 'components/custom/AsyncAutocomplete/AsyncAutocomplete';
import { CollaboratorDTO } from 'types/dto/collaborator.dto';
import { SendTaskDTO, Subscription } from 'types/dto/task.dto';
import { Cancel, CancelOutlined } from '@mui/icons-material';
import { FormikErrors } from 'formik';
import useWebsocket from 'hooks/useWebsocket';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { CustomTooltip } from '../CustomTooltip';
import { initials } from 'utils/formattation';
import { AskConfirmation } from '../AskConfirmation';

const StyledPopper = styled(Popper)(() => ({
  backgroundColor: '#ffffff',
  boxShadow: `0 8px 24px rgba(149, 157, 165, 0.2)`,
  borderRadius: 6,
  width: 300,
  zIndex: 1800
}));

interface Props {
  disabled?: boolean;
  isWatcher: boolean;
  setCollaboratorArray: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          assignee: Subscription[];
          watchers: Subscription[];
        }>
      >;
  values: SendTaskDTO;
  collaboratorArray: Subscription[];
  otherCollaboratorArray: Subscription[];
}
const TaskCollaboratorSelector = ({
  disabled,
  values,
  isWatcher,
  collaboratorArray,
  otherCollaboratorArray,
  setCollaboratorArray
}: Props) => {
  const theme = useTheme();
  const { openModal, closeModal } = useModal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showPopper, setShowPopper] = useState<boolean>(false);
  const [shownValue, setShownValue] = useState<string>('');
  const socket = useWebsocket();

  const openAssignee = Boolean(anchorEl);

  const handleAssigneeClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAssigneeClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteCollaborator = (id: number) => {
    if (values?.id) {
      socket.emit('updateTask', { id: values.id });
    }
    setCollaboratorArray(
      isWatcher ? 'watchers' : 'assignee',
      collaboratorArray && collaboratorArray.length && collaboratorArray!.filter((u) => u.id !== id)
    );
  };

  const optUpdate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.currentTarget.remove();
  };

  const assignCollaborator = (user: CollaboratorDTO) => {
    if (values?.id) {
      socket.emit('updateTask', { id: values.id });
    }
    setCollaboratorArray(
      isWatcher ? 'watchers' : 'assignee',
      collaboratorArray
        ? [...collaboratorArray, { id: user.id, firstname: user.firstname, lastname: user.lastname, isWatcher }]
        : [{ id: user.id, firstname: user.firstname, lastname: user.lastname, isWatcher }]
    );
  };

  return (
    <Grid item xs={8}>
      <Transition>
        <Box className="flex ali-center" maxWidth={'100%'} flexWrap={'wrap'}>
          <Box className="flex dir-col" mr={2}>
            <IconButton onClick={handleAssigneeClick} disabled={disabled ?? false}>
              <PersonAddIcon color="action" />
            </IconButton>
            <Transition>
              <StyledPopper open={openAssignee} id={'assignee-popper'} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={handleAssigneeClose}>
                  <div>
                    <AsyncAutoComplete<CollaboratorDTO>
                      endpoint="/users"
                      query={{
                        filterBy: JSON.stringify(
                          [
                            { field: 'hasAdminPermissions', value: { equals: true } },
                            otherCollaboratorArray.length || collaboratorArray.length
                              ? {
                                  field: 'id',
                                  value: {
                                    notIn: [
                                      ...(collaboratorArray?.map((c) => c.id) ?? []),
                                      ...otherCollaboratorArray?.map((o) => o.id ?? [])
                                    ]
                                  }
                                }
                              : undefined
                          ].filter((r) => r)
                        )
                      }}
                      open
                      getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.firstname} ${option.lastname}`)}
                      id={'async-users'}
                      fullWidth
                      value={shownValue}
                      renderInput={(params) => (
                        <TextField {...params} label="Assegna a:" value={shownValue} onChange={(e) => setShownValue(e.target.value)} />
                      )}
                      ListboxProps={{ style: { maxHeight: 270 } }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Chip
                            label={`${option.firstname} ${option.lastname}`}
                            avatar={<Avatar sx={{ bgcolor: '#868D8E' }}>{initials(option.firstname, option.lastname)}</Avatar>}
                            size="large"
                            deleteIcon={<CancelRoundedIcon />}
                            className="clickable"
                            sx={{
                              '& .MuiChip-label': {
                                fontWeight: '400',
                                fontSize: '13px'
                              },
                              bgcolor: `${theme.palette.action.hover}`,
                              mr: 1
                            }}
                            onClick={(e) => {
                              optUpdate(e);
                            }}
                          ></Chip>
                        </li>
                      )}
                      onChange={(item) => {
                        const user = item as CollaboratorDTO;
                        setCollaboratorArray('data.subscriptions', [
                          ...collaboratorArray,
                          { id: user.id, firstname: user.firstname, lastname: user.lastname }
                        ]);
                        assignCollaborator(user);
                        setShownValue('');
                        setAnchorEl(null);
                      }}
                    />
                  </div>
                </ClickAwayListener>
              </StyledPopper>
            </Transition>
          </Box>
          <Box mr={1}>
            <AvatarGroup
              max={5}
              onClick={() => setShowPopper(true)}
              className="clickable"
              sx={{ position: 'relative', '& .MuiAvatar-root': { width: 35, height: 35, fontSize: 16 } }}
            >
              {collaboratorArray.length &&
                collaboratorArray.map(({ id, firstname, lastname }, i) => (
                  <CustomTooltip content={`${firstname} ${lastname}`} key={`collaborator-${i}`}>
                    <Avatar
                      sx={{
                        position: 'relative',
                        zIndex: 30,
                        fontSize: 16,
                        backgroundColor: '#bfbfbf',
                        ':hover': { backgroundColor: 'common.white', border: `2px solid ${theme.palette.action.selected}` }
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        openModal({
                          title: 'Conferma',
                          content: (
                            <AskConfirmation
                              confirmationText={
                                <Typography>
                                  {values
                                    ? `Vuoi rimuovere ${firstname} ${lastname} da ${values.title}?`
                                    : `Vuoi rimuovere ${firstname} ${lastname}?`}
                                </Typography>
                              }
                              onConfirmCallback={() => {
                                const optDeleteCollab = collaboratorArray.filter((c) => c.id !== id);
                                if (values.subscriptions.length > 1) {
                                  setCollaboratorArray('data.subscriptions', optDeleteCollab);
                                  handleDeleteCollaborator(id);
                                } else {
                                  dispatch(
                                    openSnackbar({
                                      message: `Il task deve avere almeno un utente assegnato.`,
                                      open: true,
                                      variant: 'error',
                                      anchorOrigin: { vertical: 'top', horizontal: 'right' },
                                      key: `delete-last-assignee`
                                    })
                                  );
                                }
                                closeModal();
                              }}
                            />
                          )
                        });
                      }}
                    >
                      {initials(firstname, lastname)}
                      <CancelOutlined
                        sx={{ position: 'absolute', color: 'error.main', width: 35, height: 35, opacity: 0, ':hover': { opacity: 0.9 } }}
                      />
                    </Avatar>
                  </CustomTooltip>
                ))}
            </AvatarGroup>
            {showPopper && (
              <ClickAwayListener onClickAway={() => setShowPopper(false)}>
                <Grow in={showPopper}>
                  <Box
                    minWidth={240}
                    position="absolute"
                    top={300}
                    sx={{
                      backgroundColor: 'common.white',
                      zIndex: 30,
                      borderRadius: '6px',
                      boxShadow: `0 0 20px 6px ${theme.palette.action.hover}`
                    }}
                  >
                    <Box className="flex dir-col" p={2}>
                      {collaboratorArray.map(({ id, firstname, lastname }, i) => (
                        <Box key={`full-collaborator-${i}`} className="flex just-btwn ali-center">
                          <Chip
                            key={`subject-${i}`}
                            avatar={<Avatar sx={{ bgcolor: '#868D8E' }}>{initials(firstname, lastname)}</Avatar>}
                            label={`${firstname} ${lastname}`}
                          />
                          <IconButton
                            onClick={() =>
                              openModal({
                                title: 'Conferma',
                                content: (
                                  <AskConfirmation
                                    confirmationText={
                                      <Typography>
                                        {values
                                          ? `Vuoi rimuovere ${firstname} ${lastname} da ${values.title}?`
                                          : `Vuoi rimuovere ${firstname} ${lastname}?`}
                                      </Typography>
                                    }
                                    onConfirmCallback={() => {
                                      const optDeleteCollab = collaboratorArray.filter((c) => c.id !== id);
                                      if (collaboratorArray.length > 1) {
                                        setCollaboratorArray('data.subscriptions', optDeleteCollab);
                                        handleDeleteCollaborator(id);
                                      } else {
                                        dispatch(
                                          openSnackbar({
                                            message: `Il task deve avere almeno un utente assegnato.`,
                                            open: true,
                                            variant: 'error',
                                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                                            key: `delete-last-assignee`
                                          })
                                        );
                                      }
                                      closeModal();
                                    }}
                                  />
                                )
                              })
                            }
                          >
                            <Cancel sx={{ color: 'action.disabled' }} />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Grow>
              </ClickAwayListener>
            )}
          </Box>
        </Box>
      </Transition>
    </Grid>
  );
};

export default TaskCollaboratorSelector;
