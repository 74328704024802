import { Upload } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import UploadLogo from 'assets/images/icons/uploadLogo';
import { Dispatch, DragEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { viewFormats } from 'utils/fileFormats';

interface Props {
  type: 'drag' | 'button' | 'typo';
  box?: boolean;
  title?: string;
  i: number;
  multiple: boolean;
  buttonSize?: 'small' | 'medium' | 'large' | undefined;
  fileFormats: string[];
  filesData:
    | {
        files: File[];
        fileNames: string[];
        errors: {
          size: string[];
          format: string[];
        };
      }
    | undefined;
  setFilesData: Dispatch<
    React.SetStateAction<
      | {
          files: File[];
          fileNames: string[];
          errors: {
            size: string[];
            format: string[];
          };
        }
      | undefined
    >
  >;
}
const DragDropUpload = ({ type, i, box, title, buttonSize, fileFormats, multiple, filesData, setFilesData }: Props) => {
  const dispatch = useDispatch();
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const handleDrag = (e: DragEvent<HTMLFormElement | HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleSubmit(e.dataTransfer.files);
    }
  };

  const handleSubmit = (files: FileList) => {
    if (files) {
      let filesList = [];
      let fileNames: string[] = [];
      let sizeErrorNames: string[] = [];
      let formatErrorNames: string[] = [];

      for (let i = 0; i < files.length; i++) {
        if (fileFormats.includes(files[i].type)) {
          if (files[i].size < 40 * 1024 * 1024) {
            filesList.push(files[i]);
            fileNames.push(files[i].name);
          } else {
            sizeErrorNames.push(files[i].name);
          }
        } else {
          formatErrorNames.push(files[i].name);
        }
      }
      setFilesData({ files: filesList, fileNames: fileNames, errors: { size: sizeErrorNames, format: formatErrorNames } });
    }
  };

  useEffect(() => {
    if (filesData?.errors.format.length) {
      filesData.errors.format.forEach((e, i) => {
        setTimeout(() => {
          dispatch(
            openSnackbar({
              message: `Il formato del file "${e}" non è accettato.`,
              open: true,
              variant: 'error',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              key: `format-error-${i}`
            })
          );
        }, i * 150);
      });
    }
    if (filesData?.errors.size.length) {
      filesData.errors.size.forEach((e, i) => {
        setTimeout(() => {
          dispatch(
            openSnackbar({
              message: `La dimensione del file "${e}" è maggiore di 40MB.`,
              open: true,
              variant: 'error',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              key: `size-error-${i}`
            })
          );
        }, i * 150);
      });
    }
  }, [filesData]);

  return (
    <Box>
      <input
        type="file"
        accept={fileFormats.join(',')}
        id="input-file-upload"
        multiple={multiple}
        style={{ display: 'none' }}
        onChange={(e: any) => {
          handleSubmit(e.target.files);
          e.target.value = null;
        }}
        ref={uploadRef}
      />
      <label id={`label-file-upload-${i}`} htmlFor={`input-file-upload-${i}`}>
        {type === 'drag' ? (
          <Box
            className={box ? 'small-drag-n-drop-container' : 'drag-n-drop-container'}
            onClick={() => uploadRef.current?.click()}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            {box ? (
              <Box width={80} height={80} className="flex ali-center just-center">
                <IconButton>
                  <Upload fontSize="large" />
                </IconButton>
              </Box>
            ) : (
              <>
                <Box mt={2}>
                  <UploadLogo width={50} />
                </Box>
                <Typography mt={2}>
                  <Typography component="span" variant="subtitle1" mr={0.5}>
                    Clicca per caricare
                  </Typography>
                  o trascina e rilascia
                </Typography>
                <Typography variant="body2" mt={1}>
                  {viewFormats(fileFormats.join(','))} (max. 40MB)
                </Typography>
              </>
            )}
          </Box>
        ) : type === 'button' ? (
          <Button size={buttonSize ?? 'medium'} variant="contained" onClick={() => uploadRef.current?.click()}>
            {title}
          </Button>
        ) : (
          type === 'typo' && (
            <Typography variant="body2" className="textSecondary clickable" onClick={() => uploadRef.current?.click()}>
              {title}
            </Typography>
          )
        )}
      </label>
      {dragActive && (
        <div
          id={`drag-file-element-${i}`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </Box>
  );
};

export default DragDropUpload;
