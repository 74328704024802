import { Box } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const CustomTabPanel = (props: Props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      hidden={value !== index}
      minHeight={200}
      id={`task-tabpanel-${index}`}
      aria-labelledby={`task-tabpanel-${index}`}
      sx={{ border: `` }}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

export default CustomTabPanel;
