// action - state management
// import { REGISTER, LOGIN, LOGOUT } from './actions';

// types
import { createSlice } from '@reduxjs/toolkit';
import { AuthActionProps, AuthProps } from 'types/auth';

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| AUTH REDUCER ||============================== //

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setInizialized: (state) => {
      state.isInitialized = true;
    },
    setLogin: (state, action: AuthActionProps) => {
      state.isLoggedIn = true;
      state.isInitialized = true;
      state.user = action.payload;
    },
    setLogout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    }
  }
});

export default auth.reducer;
export const { setInizialized, setLogin, setLogout } = auth.actions;
