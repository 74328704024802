// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';

// import Notistack from 'components/third-party/Notistack';
import { QueryClient, QueryClientProvider } from 'react-query';

// auth-provider
import { AuthProvider } from 'contexts/AuthContext';
import { Box, Typography } from '@mui/material';
import Offline from 'components/custom/Offline';
import Idle from 'components/custom/Idle';
import useConfig from 'hooks/useConfig';
import { useEffect, useMemo } from 'react';
import { AxiosInstanceProvider } from 'contexts/AxiosInstanceContext';
import { ModalProvider } from 'contexts/ModalContext';
import { useDispatch } from 'store';
import { activeItem } from 'store/reducers/menu';
import { SnackbarProvider } from 'notistack';
import Snackbar from 'components/@extended/Snackbar';
import dayjs from 'dayjs';
import 'dayjs/locale/it';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
export const queryClient = new QueryClient();

const App = () => {
  const dispatch = useDispatch();
  const pathname = useMemo(() => window.location.pathname, []);
  console.log('rendering app');
  const { onChangeFontFamily, onChangeLocalization, i18n } = useConfig();

  useEffect(() => {
    dayjs.locale(i18n);
    onChangeFontFamily("'Work Sans', sans-serif");
    onChangeLocalization(i18n);
    const locations = pathname.split('/');
    let activeLocation = locations[0];
    if (activeLocation !== 'dashboard') {
      if (activeLocation === 'users') {
        dispatch(activeItem({ openItem: ['users'] }));
      } else {
        dispatch(activeItem({ openItem: [activeLocation] }));
      }
    }
  }, []);

  return (
    <ThemeCustomization>
      <Locales>
        <AuthProvider>
          <AxiosInstanceProvider>
            <QueryClientProvider client={queryClient}>
              <ModalProvider>
                <SnackbarProvider maxSnack={5}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100vh',
                      display: 'flex',
                      justifyContent: 'center',
                      visibility: { xs: 'visible', md: 'hidden' },
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 99999999999,
                      bgcolor: 'background.paper',
                      alignItems: 'center',
                      padding: 5
                    }}
                  >
                    <Typography variant="h2">L'app è disponibile per schermi superiori ai 10".</Typography>
                  </Box>
                  <Box sx={{ overflow: { xs: 'hidden', md: 'visible' } }}>
                    <Routes />

                    <Offline />
                    <Idle />
                  </Box>
                  <Snackbar />
                </SnackbarProvider>
              </ModalProvider>
            </QueryClientProvider>
          </AxiosInstanceProvider>
        </AuthProvider>
      </Locales>
    </ThemeCustomization>
  );
};

export default App;
