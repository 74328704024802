import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const PartnersLogo = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="30" height="21" viewBox="0 0 38 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.385 16.9234C13.9961 16.9234 16.9234 13.9961 16.9234 10.385C16.9234 6.77401 13.9961 3.84668 10.385 3.84668C6.77401 3.84668 3.84668 6.77401 3.84668 10.385C3.84668 13.9961 6.77401 16.9234 10.385 16.9234Z"
        fill="#094754"
        stroke="#094754"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M19.6155 16.9234C23.2266 16.9234 26.1539 13.9961 26.1539 10.385C26.1539 6.77401 23.2266 3.84668 19.6155 3.84668C16.0045 3.84668 13.0771 6.77401 13.0771 10.385C13.0771 13.9961 16.0045 16.9234 19.6155 16.9234Z"
        fill="#91DCF9"
        stroke="#91DCF9"
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <path
        d="M17.7272 3.04171C13.6716 -1.0139 7.09674 -1.0139 3.04113 3.04171C-0.545504 6.62834 -0.959288 12.1853 1.79787 16.2276C2.15836 16.756 2.57215 17.2588 3.04113 17.7278C3.51011 18.1968 4.01297 18.6106 4.54133 18.9711C8.58362 21.7282 14.1406 21.3144 17.7272 17.7278C21.7828 13.6722 21.7828 7.09732 17.7272 3.04171ZM10.3846 16.7727C9.32706 16.7727 8.32972 16.5154 7.45152 16.0609C7.05487 15.8553 6.6822 15.6098 6.3396 15.3288C6.0107 15.0597 5.70921 14.7582 5.44008 14.4293C5.15953 14.0867 4.91362 13.7141 4.70806 13.3174C4.25317 12.4388 3.99622 11.4419 3.99622 10.3844C3.99622 6.85598 6.85654 3.99604 10.3846 3.99604C13.9126 3.99604 16.7729 6.85636 16.7729 10.3844C16.7729 13.9124 13.9126 16.7727 10.3846 16.7727Z"
        fill="#094754"
      />
      <path
        d="M26.9587 3.04171C22.903 -1.0139 16.3282 -1.0139 12.2726 3.04171C8.68594 6.62834 8.27216 12.1853 11.0293 16.2276C11.3898 16.756 11.8036 17.2588 12.2726 17.7278C12.7416 18.1968 13.2444 18.6106 13.7728 18.9711C17.8151 21.7282 23.372 21.3144 26.9587 17.7278C31.0143 13.6722 31.0143 7.09732 26.9587 3.04171ZM19.616 16.7727C18.5585 16.7727 17.5612 16.5154 16.683 16.0609C16.2863 15.8553 15.9136 15.6098 15.571 15.3288C15.2421 15.0597 14.9407 14.7582 14.6715 14.4293C14.391 14.0867 14.1451 13.7141 13.9395 13.3174C13.4846 12.4388 13.2277 11.4419 13.2277 10.3844C13.2277 6.85598 16.088 3.99604 19.616 3.99604C23.144 3.99604 26.0043 6.85636 26.0043 10.3844C26.0043 13.9124 23.144 16.7727 19.616 16.7727Z"
        fill="#1ED367"
      />
      <path
        d="M15.0009 5.8661C14.0185 4.89352 12.7216 4.23804 11.2764 4.06543C8.52521 7.62305 8.40431 12.5578 10.9137 16.2367C11.0289 16.4059 11.1506 16.572 11.2768 16.7355C12.722 16.5629 14.0189 15.9074 15.0013 14.9349C14.8432 14.7782 14.6926 14.6132 14.5515 14.441C14.2713 14.0988 14.0257 13.7265 13.8204 13.3304C13.366 12.4528 13.1094 11.4571 13.1094 10.4009C13.1094 8.62718 13.8333 7.02308 15.0013 5.86648L15.0009 5.8661Z"
        fill="#91DCF9"
      />
      <path
        d="M26.824 17.7342C23.626 20.9322 18.8597 21.6044 15.0017 19.7528C16.0298 19.2593 16.994 18.5867 17.8465 17.7342C18.1647 17.416 18.4578 17.0818 18.7263 16.735C18.9768 16.7647 19.2316 16.7803 19.4901 16.7803C23.0139 16.7803 25.8708 13.9238 25.8708 10.3996C25.8708 6.87546 23.0139 4.01895 19.4901 4.01895C19.2316 4.01895 18.9764 4.03492 18.7259 4.06457C18.4578 3.71782 18.1647 3.384 17.8465 3.06576C16.9937 2.21333 16.0294 1.54074 15.001 1.04723C18.8593 -0.804768 23.626 -0.132178 26.824 3.06576C30.8747 7.11613 30.8747 13.6835 26.824 17.7342Z"
        fill="#91DCF9"
      />
      <path
        d="M16.9232 10.3842C16.9232 12.0357 16.1884 13.5313 15.0002 14.6147C13.812 13.5313 13.0771 12.0357 13.0771 10.3842C13.0771 8.73272 13.812 7.23708 15.0002 6.15332C16.1884 7.23708 16.9232 8.73236 16.9232 10.3842Z"
        fill="#F0FF32"
      />
      <path
        d="M15.3848 5.70514C14.3572 4.70135 13.0006 4.02483 11.489 3.84668C8.61122 7.51848 8.48475 12.6116 11.1096 16.4086C11.2301 16.5832 11.3573 16.7547 11.4894 16.9234C13.001 16.7453 14.3576 16.0687 15.3852 15.0649C15.2198 14.9033 15.0623 14.733 14.9147 14.5552C14.6216 14.202 14.3647 13.8179 14.15 13.409C13.6747 12.5033 13.4063 11.4756 13.4063 10.3854C13.4063 8.55484 14.1635 6.89925 15.3852 5.70553L15.3848 5.70514Z"
        fill="#91DCF9"
      />
      <path
        d="M14.6156 14.4051C15.6433 15.3498 16.9998 15.9865 18.5115 16.1542C21.3892 12.6984 21.5157 7.90486 18.8909 4.33124C18.7704 4.16689 18.6431 4.00549 18.5111 3.84668C16.9994 4.01435 15.6429 4.65108 14.6152 5.59582C14.7807 5.74799 14.9382 5.90827 15.0857 6.07558C15.3788 6.40798 15.6357 6.76955 15.8505 7.15439C16.3257 8.0068 16.5942 8.97407 16.5942 10.0001C16.5942 11.723 15.837 13.2812 14.6152 14.4047L14.6156 14.4051Z"
        fill="#094754"
      />
    </svg>
  </SvgIcon>
);

export default PartnersLogo;
