import _ from 'lodash';
import { SendTaskDTO } from 'types/dto/task.dto';

export const taskDataCheck = (i: SendTaskDTO, c: SendTaskDTO, type: 'async' | 'instant'): boolean => {
  if (type === 'async') {
    if (
      i.title !== c.title ||
      !_.isEqual(
        i.todos?.map((t) => t.title),
        c.todos?.map((t) => t.title)
      ) ||
      i.description !== c.description
    ) {
      return true;
    }
    return false;
  }
  if (
    i.public !== c.public ||
    !_.isEqual(
      i.todos?.map((t) => t.done),
      c.todos?.map((t) => t.done)
    ) ||
    !_.isEqual(i.comments, c.comments) ||
    i.expireAt !== c.expireAt ||
    !_.isEqual(i.resource, c.resource) ||
    !_.isEqual(i.subject, c.subject) ||
    i.state !== c.state ||
    !_.isEqual(i.subscriptions, c.subscriptions)
  ) {
    return true;
  }
  return false;
};
interface Data {
  [key: string]: any;
}

export const taskDataSelector = (obj1: Data, obj2: Data, lastEdit: string): Data => {
  const result: Data = {};
  _.forEach(obj1, (value, key) => {
    const value2 = _.get(obj2, key);
    if (!_.isEqual(value, value2)) {
      result[key] = value2;
    } else if (_.isObject(value) && _.isObject(value2)) {
      const nestedResult = taskDataSelector(value, value2, lastEdit);
      if (!_.isEmpty(nestedResult)) {
        result[key] = nestedResult;
      }
    } else if (_.isArray(value) && _.isArray(value2)) {
      if (_.isEqual(_.sortBy(value), _.sortBy(value2))) {
        return result;
      }
      result[key] = value2;
    } else if (key === 'lastEdit') {
      result[key] = value;
    }
  });
  return result;
};
