import { Skeleton } from '@mui/material';
import { Box } from '@mui/material';
export const SingleTaskLoading = () => {
  return (
    <Box className="right-drawer-el-container">
      <Box className="right-drawer-header">
        <Box className="flex ali-center just-btwn">
          <Skeleton animation="wave" sx={{ width: 100, height: 40, ml: 3 }} />
          <Box className="flex ali-center just-btwn">
            <Skeleton animation="wave" sx={{ width: 230, height: 60, mr: 2 }} />
            <Skeleton animation="wave" sx={{ width: 230, height: 60, mr: 2 }} />
          </Box>
        </Box>
      </Box>

      <Box className="standard-margin-container">
        <Box className="flex ali-center just-btwn">
          <Skeleton animation="wave" sx={{ width: 100, height: 40 }} />
          <Skeleton animation="wave" sx={{ width: 130, height: 60, borderRadius: 6 }} />
        </Box>
        <Skeleton animation="wave" sx={{ width: 130, height: 30, mr: 2 }} />
        <Box className="flex ali-center just-btwn">
          <Skeleton animation="wave" sx={{ width: 130, height: 60, mr: 2 }} />
          <Skeleton animation="wave" sx={{ width: 70, height: 60 }} />
        </Box>
        <Box className="flex ali-center">
          <Box width="50%">
            <Skeleton animation="wave" sx={{ mr: 0.5, width: 70, ml: 1.5 }} />
            <Skeleton animation="wave" variant="circular" sx={{ margin: 1.5, width: 40, height: 40 }} />
          </Box>
          <Box width="50%">
            <Skeleton animation="wave" sx={{ mr: 0.5, width: 100, ml: 1.5 }} />
            <Skeleton animation="wave" variant="circular" sx={{ margin: 1.5, width: 40, height: 40 }} />
          </Box>
        </Box>
        <Skeleton animation="wave" sx={{ mr: 0.5, width: 100 }} />
        <Skeleton animation="wave" sx={{ mr: 0.5, width: '100%', height: 200, mt: -4 }} />
        <Box className="flex ali-center" mt={-4}>
          <Skeleton animation="wave" sx={{ width: 130, height: 60, mr: 0.5 }} />
          <Skeleton animation="wave" sx={{ width: 130, height: 60, mr: 0.5 }} />
          <Skeleton animation="wave" sx={{ width: 130, height: 60, mr: 0.5 }} />
        </Box>
        <Skeleton animation="wave" sx={{ width: '100%', height: 60 }} />
      </Box>
      <Box className="task-footer">
        <Skeleton animation="wave" sx={{ mr: 0.5, width: 70, ml: 1.5 }} />
        <Skeleton animation="wave" sx={{ width: 130, height: 60, borderRadius: 6 }} />
      </Box>
    </Box>
  );
};
