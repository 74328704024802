import { Box, IconButton, InputBase, Paper, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { KeyboardEvent, useState } from 'react';
import { SendTaskDTO } from 'types/dto/task.dto';
import { FormikErrors } from 'formik';

interface Props {
  comments: { description: string }[];
  setValues: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          data: SendTaskDTO | undefined;
        }>
      >;
}

export const AddComment = ({ comments, setValues }: Props) => {
  const theme = useTheme();
  const [currentComment, setCurrentComment] = useState<string>('');

  const handleAddComment = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.code === 'Enter' && currentComment !== '') {
      e.preventDefault();
      setValues('data.comments', [{ description: currentComment }, ...comments]);
      setCurrentComment('');
    }
    if (e.code === 'Enter' && currentComment === '') {
      e.preventDefault();
    }
  };

  const handleAddCommentClick = () => {
    if (currentComment !== '') {
      setValues('data.comments', [{ description: currentComment }, ...comments]);
      setCurrentComment('');
    }
  };

  return (
    <Box mt={2}>
      <Paper
        component="form"
        sx={{
          display: 'flex',
          boxShadow: '0 0 0 0',
          border: `2px solid ${theme.palette.divider}`,
          alignItems: 'center',
          width: '100%'
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Aggiungi commento"
          value={currentComment}
          onChange={(e) => setCurrentComment(e.currentTarget.value)}
          inputProps={{ 'aria-label': 'add comment' }}
          onKeyDown={(e) => handleAddComment(e)}
        />
        <IconButton
          type="button"
          sx={
            currentComment !== ''
              ? { p: '10px' }
              : { p: '10px', color: theme.palette.action.selected, ':hover': { color: theme.palette.action.selected } }
          }
          aria-label="add-comment"
          onClick={() => handleAddCommentClick()}
        >
          <AddIcon />
        </IconButton>
      </Paper>
    </Box>
  );
};
