import { FormattedMessage } from 'react-intl';

// assets
import SettingsLogo from 'assets/images/icons/navigation/Settings';

// type
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const settings: NavItemType = {
  id: 'settings',
  title: '',
  type: 'group',
  children: [
    {
      id: 'settings',
      title: <FormattedMessage id="settings" />,
      type: 'item',
      icon: SettingsLogo,
      permissions: [{ resource: null, operations: ['read'] }],
      url: '/settings'
    }
  ]
};

export default settings;
