import { createSlice } from '@reduxjs/toolkit';

interface IsAuth {
  isAuth?: boolean;
  rememberMe: boolean;
}
const initialState: IsAuth = {
  isAuth: undefined,
  rememberMe:
    localStorage['stayActive'] !== 'undefined' && (localStorage['stayActive'] === 'true' || localStorage['stayActive'] === 'false')
      ? localStorage['stayActive'] === 'true'
        ? true
        : false
      : false
};

const authorization = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    authOk: (state) => {
      state.isAuth = true;
    },
    authFalse: (state) => {
      state.isAuth = false;
    },
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload;
    }
  }
});

export default authorization.reducer;
export const { authOk, authFalse, setRememberMe } = authorization.actions;
