import Label from './Label';

const Single = ({ path }: { path: string[] }) => {
  let single: JSX.Element = <></>;
  switch (path[0]) {
    case 'dashboard':
      single = <Label label="Dashboard" />;
      break;
    case 'analytics':
      single = <Label label="Analytics" />;
      break;
    case 'faq':
      single = <Label label="FAQ" />;
      break;
    case 'referral-codes':
      single = <Label label="Referral codes" />;
      break;
    case 'partners':
      single = <Label label="Partners" />;
      break;
    case 'platform-contracts':
      single = <Label label="Documenti platform" />;
      break;
    case 'settings':
      single = <Label label="Impostazioni" />;
      break;
    case 'log':
      single = <Label label="Log della piattaforma" />;
      break;
    case 'fast-registration-users':
      single = <Label label="Utenti registrazione fast" />;
      break;
    case 'switcho-users':
      single = <Label label="Utenti Switcho" />;
      break;
    case 'autopilot':
      single = <Label label="Autopilot" />;
      break;
    default:
      single = <></>;
      break;
  }
  return single;
};
export default Single;
