// assets

// type
import FaqLogo from 'assets/images/icons/navigation/Faq';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const faq: NavItemType = {
  id: 'faq',
  title: '',
  type: 'group',
  children: [
    {
      id: 'faq',
      title: 'FAQ',
      type: 'item',
      icon: FaqLogo,
      permissions: [{ resource: null, operations: ['read', 'create', 'update', 'delete'] }],
      url: '/faq'
    }
  ]
};

export default faq;
