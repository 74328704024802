import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/material';
import NoElement from 'assets/images/illustrations/noElement';

const NoNotes = () => {
  const theme = useTheme();
  return (
    <Box mt={2}>
      <Box className="flex ali-center dir-col" padding={6} sx={{ backgroundColor: `${theme.palette.action.hover}` }}>
        <NoElement width={300} />
        <Typography variant="h6" mt={4}>
          Nessuna interazione
        </Typography>
      </Box>
    </Box>
  );
};

export default NoNotes;
