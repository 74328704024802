import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import * as process from 'process';
import { dispatch, useSelector } from 'store';
import { SocketResponse } from 'types/socket-response';

import { setFetchNotification } from 'store/reducers/helpers';
import { useLocation } from 'react-router';
import { queryClient } from 'App';

export const WebsocketContext = createContext<Socket | null>(null);

export const WebsocketProvider = ({ children }: { children: React.ReactElement }) => {
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const { rememberMe } = useSelector((state) => state.authorization);
  const location = useLocation();

  const socket = useMemo(
    () =>
      io(process.env.REACT_APP_SERVER_URL, {
        path: '/tcnwbsckt',
        transports: ['websocket', 'polling'],
        auth: {
          token: localStorage['stayActive'] === 'true' ? localStorage['accessToken'] : sessionStorage['accessToken']
        },
        extraHeaders: {
          Authorization: `Bearer ${localStorage['stayActive'] === 'true' ? localStorage['accessToken'] : sessionStorage['accessToken']}`
        },
        reconnection: false,
        autoConnect: true
      }),
    []
  );

  useEffect(() => {
    if (!socket.connected && refreshCount <= 3) {
      socket.connect();
    }
  }, [location]);

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }

    socket.on('connect', () => {
      console.log('websocket connesso');
    });

    socket.on('connect_error', async (err) => {
      console.log('Connection websocket failed', err);
      if (refreshCount <= 3) {
        setRefreshCount(refreshCount + 1);
        //@ts-ignore
        socket.auth.token = rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken'];
        socket.connect();
      } else {
        socket.close();
      }
    });

    socket.on('disconnect', () => {
      console.log('websocket disconnesso');
    });

    socket.on('resource/task', (data) => {
      queryClient.refetchQueries('get-all-tasks');
      queryClient.refetchQueries('get-tasks-readings');
    });

    socket.on('resource/orderQuote', (data) => {
      if (data.env === 'api') {
        data.subjects.forEach((s: string) => queryClient.refetchQueries(`get-order-${s}`));
      }
    });

    socket.on('resource/note', (data) => {
      queryClient.refetchQueries('get-all-notes');
    });

    socket.on('resource/order', (data) => {
      data.subjects.forEach((s: string) => queryClient.refetchQueries(`get-order-${s}`));
    });

    socket.on('notifications', (data: SocketResponse) => {
      queryClient.refetchQueries('get-all-notifications');
      dispatch(setFetchNotification({ id: data.id, env: data.env, userId: data.userId }));
    });

    return () => {
      socket.off('connect');
      socket.off('connect_error');
      socket.off('notifications');
      socket.disconnect();
      socket.off('disconnect');
    };
  }, []);

  return <WebsocketContext.Provider value={socket}>{children}</WebsocketContext.Provider>;
};
