import { TextareaAutosize, styled } from '@mui/material';

export const StyledTextarea = styled(TextareaAutosize)(
  () => `
		width: 100%;
		font-family: 'Work Sans', sans-serif;
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.5;
		padding: 12px;
		border-radius: 4px;
		border: 2px solid #e5e8e8;
		resize: none;
		&:disabled {
			background-color: #FFFFFF;
			color: #000000;
		}
		// firefox
		&:focus-visible {
		  outline: 0;
		}
		`
);
export const StyledTitleTextarea = styled(TextareaAutosize)(
  () => `
		width: 100%;
		font-family: 'Work Sans', sans-serif;
		font-size: 1.2rem;
		font-weight: 700;
		line-height: 1;
		padding-right: 12px;
		padding-left: 12px;
		border: none;
		resize: none;
		color: #094754;
		&:disabled {
			background-color: #FFFFFF;
			color: #094754;
		}
		// firefox
		&:focus-visible {
		  outline: 0;
		}
		`
);
export const TodoTextarea = styled(TextareaAutosize)(
  () => `
		width: 100%;
		font-family: 'Work Sans', sans-serif;
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.5;
		padding: 4px;
		border-radius: 4px;
		border: none;
		resize: none;
		&:disabled {
			background-color: #FFFFFF;
			color: #000000;
		}
		// firefox
		&:focus-visible {
		  outline: 0;
		}
		`
);
export const StyledTextareaComment = styled(TextareaAutosize)(
  () => `
		width: 80%;
		font-family: 'Work Sans', sans-serif;
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.5;
		padding-left: 12px;
		border-radius: 4px;
		
		// firefox
		&:focus-visible {
		  outline: 0;
		}
		`
);
