import { useRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Badge, Box, IconButton } from '@mui/material';
// assets
import NotificationsIcon from '@mui/icons-material/Notifications';
import { dispatch, useSelector } from 'store';
import { setActiveRightItem, setRightDrawerOpen } from 'store/reducers/menu';
import { CountedData } from 'types/counted-data';
import { NotificationType } from 'types/dto/notification.dto';
import { useEndpoint } from 'hooks/useEndpoint';

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme = useTheme();
  const anchorRef = useRef<any>(null);
  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.200';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'transparent';
  const { activeRightItem, rightDrawerOpen } = useSelector((state) => state.menu);
  const { user } = useSelector((state) => state.auth);

  const getReadings = useEndpoint<CountedData<NotificationType>, 'get'>({
    method: 'get',
    endpoint: '/notifications',
    queryKey: 'get-all-notifications',
    queryParams: {
      filterBy: JSON.stringify([
        { field: 'readAt', value: { equals: null } },
        { field: ['user', 'id'], value: { not: user?.id } }
      ])
    },
    options: {}
  });

  const handleRightDrawerToggle = () => {
    if (activeRightItem === 'notification' && rightDrawerOpen) {
      dispatch(setRightDrawerOpen(false));
    } else {
      dispatch(setActiveRightItem('notification'));
      dispatch(setRightDrawerOpen(true));
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        sx={{
          color: 'text.primary',
          '&.MuiIconButton-root:hover': { bgcolor: `${theme.palette.action.hover}`, borderRadius: 1 },
          bgcolor: rightDrawerOpen && activeRightItem === 'notification' ? iconBackColorOpen : iconBackColor,
          borderRadius: 2
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={rightDrawerOpen ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleRightDrawerToggle}
      >
        <Badge color="error" variant={getReadings.data?.data.data && getReadings.data?.data.data.length ? 'dot' : 'light'}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </Box>
  );
};

export default Notification;
