import { Box, Typography } from '@mui/material';
import DeleteIcon from 'assets/images/icons/navigation/Delete';
import EmbedFile from 'components/custom/embedFile';
import dayjs from 'dayjs';
import { FormikErrors } from 'formik';
import useModal from 'hooks/useModal';
import { SendTaskDTO } from 'types/dto/task.dto';

interface Props {
  uploads: File[];
  setValues: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          data: SendTaskDTO | undefined;
        }>
      >;
}
export const NewUploadsList = ({ uploads, setValues }: Props) => {
  const { openModal } = useModal();

  const handleDeleteUpload = (i: number) => {
    const filtered = [...uploads];

    setValues(
      'data.uploads',
      filtered.filter((a, index) => index !== i)
    );
  };

  return (
    <Box mt={3} pr={2}>
      {uploads && uploads.length ? (
        uploads.map((u, index) => (
          <Box key={`upload-${index}`} className="flex just-btwn ali-center">
            <Box width={450}>
              <Typography
                flexGrow={1}
                className="truncate clickable"
                variant="body2"
                onClick={() => openModal({ title: u.name, content: <EmbedFile local fileCode={`${URL.createObjectURL(u)}`} /> })}
              >
                {u.name}
              </Typography>
            </Box>
            <Typography variant="body2">{dayjs(new Date()).format('DD/MM/YYYY HH:mm')}</Typography>
            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'flex-end',
                flexGrow: 1,
                '&.MuiBox-root': { opacity: 0.2 },
                '&.MuiBox-root:hover': { opacity: 1 }
              }}
              onClick={() => handleDeleteUpload(index)}
            >
              <DeleteIcon width={16} />
            </Box>
          </Box>
        ))
      ) : (
        <></>
      )}
    </Box>
  );
};
