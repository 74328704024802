import { ReactNode } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery, AppBarProps, IconButton } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import useConfig from 'hooks/useConfig';
// assets
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

// typs
import { LAYOUT_CONST } from 'types/config';
import process from 'process';
// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const Header = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  // header content
  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'transparent';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'transparent';

  // common header
  const mainHeader: ReactNode = (
    <Toolbar sx={{ bgcolor: process.env.NODE_ENV === 'production' ? 'background.paper' : 'secondary.light' }}>
      {!isHorizontal ? (
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          sx={{
            color: 'text.primary',
            bgcolor: open ? iconBackColorOpen : iconBackColor,
            ml: { xs: 0, lg: -2 },
            '&:hover': { bgcolor: 'secondary.light' }
          }}
        >
          {!open ? <MenuIcon /> : <MenuOpenIcon />}
        </IconButton>
      ) : null}
      <HeaderContent />
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      backgroundColor: 'Background.paper',
      borderTop: process.env.NODE_ENV === 'production' ? '4px solid #F0FF32' : `4px solid #C8D600`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: 1200,
      width: isHorizontal ? '100%' : open ? 'calc(100% - 260px)' : { xs: '100%', md: 'calc(100% - 60px)', lg: 'calc(100% - 60px)' }
    }
  };

  return (
    <>
      {!downLG ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
