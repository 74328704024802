import { Dispatch } from 'react';

export const endpointSetter = (section: { slug: string; name: string }, setEndpoint: Dispatch<React.SetStateAction<string>>) => {
  const resourceEndpoint = endpointReturn(section);
  if (!resourceEndpoint) return;
  setEndpoint(resourceEndpoint);
};

export const endpointReturn = (section: { slug: string; name: string } | string | undefined | null): string => {
  if (typeof section !== 'string') {
    if (section?.slug) {
      if (section.slug === 'user') {
        return '/users';
      }
      if (section.slug === 'building') {
        return '/buildings';
      }
      if (section.slug === 'order') {
        return '/orders';
      }
      return '';
    }
  }
  if (typeof section === 'string') {
    if (section === 'user') {
      return '/users';
    }
    if (section === 'building') {
      return '/buildings';
    }
    if (section === 'order') {
      return '/orders';
    }
    return '';
  }
  return '';
};
