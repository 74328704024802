import { useSelector } from 'store';

export const usePermissions = () => {
  const { user } = useSelector((state) => state.auth);

  const checkPermission = (permissions: { resource: string | null; operations: string[] }[]) => {
    if (!user) return false;

    const userPermissions = user.permissions;
    let isAuthorized: boolean = false;
    const superOperations = userPermissions.find(
      (permission) => permission.resource === null && permission.environment === 'server'
    )?.operations;
    if (superOperations) {
      for (const requestedPermission of permissions) {
        if (requestedPermission.operations.every((operation) => superOperations.includes(operation))) {
          isAuthorized = true;
        }
      }
      return isAuthorized;
    }
    for (const requestedPermission of permissions) {
      const operationsToCheck = userPermissions.find((userPerm) => userPerm.resource === requestedPermission.resource)?.operations;
      if (!operationsToCheck) {
        isAuthorized = false;
        break;
      }
      if (requestedPermission.operations.every((operation) => operationsToCheck.includes(operation))) {
        isAuthorized = true;
      }
    }
    return isAuthorized;
  };

  return {
    checkPermission
  };
};
