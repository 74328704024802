import { createSlice } from '@reduxjs/toolkit';

// types
import { SnackbarActionProps, SnackbarProps } from 'types/snackbar';

const initialState: SnackbarProps & { count: number } = {
  open: false,
  message: 'Note archived',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  variant: 'default',
  transition: 'Fade',
  actionButton: false,
  persist: false,
  key: '',
  count: 0
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar(state, { payload }: SnackbarActionProps) {
      const { open, message, anchorOrigin, variant, transition, actionButton, persist, key } = payload;

      state.open = open || initialState.open;
      state.message = message || initialState.message;
      state.anchorOrigin = anchorOrigin || initialState.anchorOrigin;
      state.variant = variant || initialState.variant;
      state.transition = transition || initialState.transition;
      state.actionButton = actionButton || initialState.actionButton;
      state.persist = persist;
      state.key = key + `${state.count + 1}`;
      state.count += 1;
    },

    closeSnackbar(state, action: { payload: string }) {
      state.open = false;
      state.count -= 1;
      state.key = action.payload;
    }
  }
});

export default snackbar.reducer;

export const { closeSnackbar, openSnackbar } = snackbar.actions;
