import { useEffect, useState } from 'react';

export const useWindowLayout = () => {
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight > 0) {
        setHeight(window.innerHeight);
      }
      if (window.innerWidth > 0) {
        setWidth(window.innerWidth);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { windowHeight: height, windowWidth: width };
};
