import { CloseOutlined } from '@mui/icons-material';
import { Box, FormControl, Typography } from '@mui/material';
import { endpointReturn } from 'utils/selectEndpoint/endpointSetter';
import { SectionSelector } from '../../shared-components/SectionSelector';
import { Subject } from 'types/components/subjectFilter';
import { SubjectFilter } from 'components/custom/AsyncSelector/SubjectFilter';
import { Section } from 'types/components/sectionFilter';

interface Props {
  onClose: () => void;
  resource: Section;
  setResource: React.Dispatch<React.SetStateAction<Section>>;
  subject?: Subject;
  setSubject: React.Dispatch<React.SetStateAction<Subject>>;
  setStateFilter: React.Dispatch<React.SetStateAction<any>>;
}
const Header = ({ onClose, resource, setResource, subject, setSubject, setStateFilter }: Props) => {
  const endpoint = endpointReturn(resource);

  return (
    <Box className="right-drawer-header">
      <Box className="header-container">
        <Box className="flex ali-center">
          <CloseOutlined className="clickable" style={{ fontSize: 22, marginRight: 14, color: '#9aa4a7' }} onClick={onClose} />
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            Task
          </Typography>
        </Box>
        <Box className="flex ali-center">
          <SectionSelector section={resource} setSection={setResource} setStateFilter={setStateFilter} setSubject={setSubject} />
          <FormControl
            sx={{
              width: 230
            }}
          >
            <SubjectFilter key="tasks-subject" mt={0} endpoint={endpoint} subject={subject} setSubject={setSubject} />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
