export interface CronJob {
  title: string;
  jobs: Job[];
}

export interface Job {
  id: string;
  name: CronJobName;
  data: Data;
  opts: Opts;
  progress: number;
  delay: number;
  timestamp: number;
  attemptsMade: number;
  stacktrace: string[];
  returnvalue: null;
  finishedOn: number | null;
  processedOn: number | null;
  failedReason?: string;
}

export interface Data {
  orderCode?: string;
  buildingId?: number;
  vat?: string;
  userId?: number;
  from?: string;
  to?: string;
  template?: string;
  templateData?: DataTemplateData;
  subject?: number | string;
  lat?: number;
  lng?: number;
  holderId?: number;
  env?: Env;
  resource?: Resource;
  operation?: Operation;
  subjectName?: string;
  description?: Description;
  tag?: string;
  emailConfig?: EmailConfig;
  old?: Old;
  new?: New;
  envFilter?: Env[];
  emailSubject?: string;
}

export interface Description {
  it: string;
}

export interface EmailConfig {
  subject: string;
  templateData: EmailConfigTemplateData;
}

export interface EmailConfigTemplateData {
  message: string;
}

export enum Env {
  API = 'api',
  Server = 'server'
}

export interface New {
  title: string;
  code: string;
  createdAt: string;
  state: State;
  orderCategory: OrderCategory;
  orderConfigurations: OrderConfiguration[];
}

export interface OrderCategory {
  name: string;
}

export interface OrderConfiguration {
  total: string;
  orderWallets: any[];
}

export interface State {
  key: string;
}

export interface Old {
  state: State;
}

export enum Operation {
  Update = 'update'
}

export enum Resource {
  Building = 'building',
  Order = 'order',
  Task = 'task',
  User = 'user'
}

export interface DataTemplateData {
  email?: string;
  phone?: string;
  message?: string;
  tag?: string;
  siteUrl?: string;
  firstname?: string;
  lastname?: string;
  supportEmail?: string;
  siteUtl?: string;
}

export enum CronJobName {
  ProcessActivateWallets = 'process-activate-wallets',
  ProcessCheckKnownLocations = 'process-check-known-locations',
  ProcessEmails = 'process-emails',
  ProcessExpireOrder = 'process-expire-order',
  ProcessNotify = 'process-notify',
  ProcessTakeRiskData = 'process-take-risk-data'
}

export interface Opts {
  removeOnComplete: number;
  removeOnFail: number;
  jobId?: string;
  delay: number;
  attempts: number;
  timestamp: number;
  priority?: number;
}

export interface MailLog {
  id: number;
  messageId: string;
  subject: string;
  bodyHtml: string;
  strippedSignature: string;
  from: string;
  to: string;
  attachments: {
    filename: string;
    size: number;
    'content-type': string;
  }[];
  deliveryStatus: DeliveryStatus;
  state: State;
}

export interface DeliveryStatus {
  'attempt-no': number;
  code: number;
  message: string;
  description: string;
  'session-seconds': number;
  'enhanced-code': string;
  'mx-host': string;
  'certificate-verified': boolean;
  tls: boolean;
  utf8: boolean;
}

export interface State {
  key: string;
  at: string;
  note: string;
  notice: string | null;
  stateHistory?: State[];
}
