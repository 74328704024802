// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import helpers from './helpers';
import auth from './auth';
import authorization from './authorization';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  helpers,
  auth,
  authorization
});

export default reducers;
