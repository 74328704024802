interface SubjectProps {
  location: string;
  buildingId: string | undefined;
  orderCode?: string;
  userId: string | undefined;
  collaboratorId: string | undefined;
  referralCode?: string;
}
interface ResourceProps {
  location: string;
}

export const resourceSelector = ({ location }: ResourceProps) => {
  if (location.includes('orders')) return 'order';
  if (location.includes('buildings')) return 'building';
  if (location.includes('collaborator')) return 'user';
  if (location.includes('users')) return 'user';
  if (location.includes('referral-codes')) return 'server/discounts';
  return null;
};

export const defaultSubjectSelector = (pathname: string, user?: string, building?: string, order?: string): string[] | undefined => {
  if (user) {
    if (pathname.includes('users')) {
      return [user];
    }
  }
  if (building) {
    if (pathname.includes('buildings')) {
      return [building];
    }
  }
  if (order) {
    if (pathname.includes('orders')) {
      return [order];
    }
  }
  return undefined;
};

export const resourceObjectSelector = (pathname: string) => {
  if (pathname.includes('users')) {
    return { slug: 'user', name: 'Utenti' };
  }
  if (pathname.includes('collaborators')) {
    return { slug: 'user', name: 'Utenti' };
  }
  if (pathname.includes('buildings')) {
    return { slug: 'building', name: 'Strutture' };
  }
  if (pathname.includes('orders')) {
    return { slug: 'order', name: 'Ordini' };
  }
  return null;
};

export const taskFilter = ({ location }: ResourceProps) => {
  if (location.includes('orders')) return { equals: ['order'] };
  if (location.includes('buildings')) return { equals: 'building' };
  if (location.includes('users')) return { equals: 'user' };
  if (location.includes('collaborators')) return { equals: 'user' };
  return { equals: null };
};

export const subjectSelector = ({ location, buildingId, orderCode, userId, collaboratorId, referralCode }: SubjectProps) => {
  if (location.includes('orders')) return [orderCode];
  if (location.includes('buildings')) return [buildingId];
  if (location.includes('users')) return [userId];
  if (location.includes('collaborators')) return [collaboratorId];
  if (location.includes('referral-codes')) return [referralCode];
  return null;
};
export const labelSetter = (endpoint: string, option: any) => {
  if (typeof option !== 'string' && option != null) {
    if (endpoint === '/buildings') {
      return `${option.name}`;
    }
    if (endpoint === '/orders') {
      return `${option.code}`;
    }
    if (endpoint === '/users') {
      return `${option.firstname} ${option.lastname}`;
    }
  }
};

export const selectSection = (filter: string | null | undefined) => {
  if (!filter || filter === 'all') {
    return null;
  } else if (filter === 'order') return { slug: filter, name: 'Ordini' };
  else if (filter === 'user') return { slug: filter, name: 'Utenti' };
  else if (filter === 'building') return { slug: filter, name: 'Strutture' };
};
