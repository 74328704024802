// types
import { createSlice } from '@reduxjs/toolkit';

export interface HelpersProps {
  headerHeight: number;
  tasksCount?: number;
  notesCount?: number;
  fetchNotification?: { id: number; env: string; userId: number };
  refetchFaq: boolean;
  refetchSettings?: { id: string };
  tabId: { tabId: number };
  maintenance: boolean;
  tableHeight?: { value: number; element: number };
  dashboardPanel?: { tab: number };
  dashboardScrollToRef: boolean;
  isEditingTask?: { id: number; userId: number };
  isEditingTaskCompleted?: { id: number };
  refetchMaintenance: boolean;
  newTaskTitle: string;
  loader: boolean;
  userExt: string;
  shareData:
    | {
        path: string;
        additionalData: Record<string, any>;
        code?: string;
      }
    | undefined;
  confirmationDialog:
    | {
        title: string;
        text: string;
        data?: {
          action?: string;
          value?: string | number;
        };
      }
    | undefined;
}

// initial state
const initialState: HelpersProps = {
  headerHeight: 0,
  maintenance: false,
  notesCount: undefined,
  tasksCount: undefined,
  fetchNotification: undefined,
  refetchFaq: false,
  refetchSettings: undefined,
  isEditingTask: undefined,
  isEditingTaskCompleted: undefined,
  shareData: undefined,
  tableHeight: undefined,
  refetchMaintenance: false,
  confirmationDialog: undefined,
  tabId: { tabId: 0 },
  dashboardPanel: undefined,
  dashboardScrollToRef: false,
  userExt: '',
  newTaskTitle: '',
  loader: false
};

// ==============================|| SLICE - HELPERS ||============================== //

const helpers = createSlice({
  name: 'helpers',
  initialState,
  reducers: {
    setHeaderHeight: (state, action) => {
      state.headerHeight = action.payload;
    },
    setTasksCount: (state, action) => {
      state.tasksCount = action.payload;
    },
    setNotesCount: (state, action) => {
      state.notesCount = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setMaintenance: (state, action) => {
      state.maintenance = action.payload;
    },
    isEditingTask: (state, action) => {
      state.isEditingTask = action.payload;
    },
    isEditingTaskCompleted: (state, action) => {
      state.isEditingTaskCompleted = action.payload;
    },
    setFetchNotification: (state, action) => {
      state.fetchNotification = action.payload;
    },
    setRefetchFaq: (state, action) => {
      state.refetchFaq = action.payload;
    },
    refetchMaintenance: (state, action) => {
      state.refetchMaintenance = action.payload;
    },
    setRefetchSettings: (state, action) => {
      state.refetchSettings = action.payload;
    },
    setShareData: (state, action) => {
      state.shareData = action.payload;
    },
    setTabId: (state, action) => {
      state.tabId = action.payload;
    },
    setNewTaskTitle: (state, action) => {
      state.newTaskTitle = action.payload;
    },
    setTableHeight: (state, action) => {
      state.tableHeight = action.payload;
    },
    setDashboardPanel: (state, action) => {
      state.dashboardPanel = action.payload;
    },
    setDashboardScrollToRef: (state, action) => {
      state.dashboardScrollToRef = action.payload;
    },
    setConfirmationDialog: (state, action) => {
      state.confirmationDialog = action.payload;
    },
    showUser: (state, action) => {
      state.userExt = action.payload;
    }
  }
});

export default helpers.reducer;

export const {
  setHeaderHeight,
  setTasksCount,
  setNotesCount,
  setRefetchFaq,
  setFetchNotification,
  setRefetchSettings,
  refetchMaintenance,
  isEditingTask,
  isEditingTaskCompleted,
  setShareData,
  setMaintenance,
  setConfirmationDialog,
  setLoader,
  setTableHeight,
  setDashboardPanel,
  setDashboardScrollToRef,
  setTabId,
  setNewTaskTitle,
  showUser
} = helpers.actions;
