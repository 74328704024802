import { StatusViewType } from 'types/custom/status';

export const taskStatusList: StatusViewType[] = [
  { state: 'created', translation: 'Bozza', color: 'primary.main', backgroundColor: 'action.selected' },
  { state: 'pending', translation: 'In lavorazione', color: 'common.white', backgroundColor: 'primary.main' },
  { state: 'paused', translation: 'In pausa', color: 'primary.main', backgroundColor: 'warning.main' },
  { state: 'deleted', translation: 'Annullato', color: 'primary.main', backgroundColor: 'error.light' },
  { state: 'completed', translation: 'Completato', color: 'primary.main', backgroundColor: 'success.main' },
  { state: 'expired', translation: 'Scaduto', color: 'primary.main', backgroundColor: 'warning.light' }
];
