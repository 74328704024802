import { Box, IconButton, InputBase, Paper, useTheme } from '@mui/material';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { SendTaskDTO, Todo } from 'types/dto/task.dto';
import { FormikErrors } from 'formik';

interface Props {
  todos: (
    | Todo
    | {
        title: string;
        done: boolean;
      }
  )[];
  setTodos: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          data: SendTaskDTO | undefined;
        }>
      >;
  disabled: boolean;
}
const AddTodo = ({ todos, setTodos, disabled }: Props) => {
  const theme = useTheme();
  const [currentToDo, setCurrentToDo] = useState<string>('');

  const handleAddTodo = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.code === 'Enter' && e.currentTarget.value !== '') {
      e.preventDefault();
      setTodos('data.todos', [...todos, { title: e.currentTarget.value, done: false }]);
      setCurrentToDo('');
    }
    if (e.code === 'Enter' && e.currentTarget.value === '') {
      e.preventDefault();
    }
  };
  const handleAddTodoClick = () => {
    if (currentToDo !== '') {
      setTodos('data.todos', [...todos, { title: currentToDo, done: false }]);
      setCurrentToDo('');
    }
  };

  return (
    <Box mt={2}>
      <Paper
        component="form"
        sx={{
          display: 'flex',
          boxShadow: '0 0 0 0',
          border: `2px solid ${theme.palette.divider}`,
          alignItems: 'center',
          width: '100%'
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, fontSize: '0.9rem' }}
          placeholder="Aggiungi todo"
          value={currentToDo}
          disabled={disabled}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setCurrentToDo(e.target.value)}
          inputProps={{ 'aria-label': 'add todo' }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleAddTodo(e);
          }}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="add-todo" onClick={handleAddTodoClick}>
          <AddIcon />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default AddTodo;
