// material-ui
import { Box } from '@mui/material';
import Delete from './delete.svg';
// ==============================|| AUTH BLUR BACK SVG ||============================== //

const DeleteIcon = ({ width, onClick }: { width: number; onClick?: () => void }) => {
  return (
    <Box onClick={onClick} className="clickable">
      <img alt="buyplus logo" src={Delete} width={width} />
    </Box>
  );
};

export default DeleteIcon;
