import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const LogLogo = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="32" height="29" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2702_51752)">
        <g clipPath="url(#clip1_2702_51752)">
          <path d="M15.3332 24H0V0H24V15.3332L21.3334 21.333L15.3336 23.9996L15.3332 24Z" fill="#094754" />
          <path d="M15.333 15.334H23.9998L15.333 24.0008V15.334Z" fill="#1ED367" />
          <path d="M19.0382 5.01953H8.36328V7.68615H19.0382V5.01953Z" fill="#91DCF9" />
          <path d="M12.4064 15.334H8.36328V17.6832H12.4064V15.334Z" fill="#49EBAA" />
          <path
            d="M24.0004 15.3324V20.2668C24.0004 22.3279 22.3294 23.9989 20.2683 23.9989H15.334L24.0008 15.332L24.0004 15.3324Z"
            fill="#91DCF9"
          />
          <path
            d="M4.40891 7.84126C5.23072 7.84126 5.89692 7.17505 5.89692 6.35325C5.89692 5.53144 5.23072 4.86523 4.40891 4.86523C3.5871 4.86523 2.9209 5.53144 2.9209 6.35325C2.9209 7.17505 3.5871 7.84126 4.40891 7.84126Z"
            fill="#F0FF32"
          />
          <path
            d="M4.40891 12.8569C5.23072 12.8569 5.89692 12.1907 5.89692 11.3689C5.89692 10.5471 5.23072 9.88086 4.40891 9.88086C3.5871 9.88086 2.9209 10.5471 2.9209 11.3689C2.9209 12.1907 3.5871 12.8569 4.40891 12.8569Z"
            fill="#F0FF32"
          />
          <path
            d="M4.32883 17.9956C5.15064 17.9956 5.81684 17.3293 5.81684 16.5075C5.81684 15.6857 5.15064 15.0195 4.32883 15.0195C3.50703 15.0195 2.84082 15.6857 2.84082 16.5075C2.84082 17.3293 3.50703 17.9956 4.32883 17.9956Z"
            fill="#F0FF32"
          />
        </g>
      </g>
      <path
        d="M17.6663 9.66602H10.6663C9.38301 9.66602 8.34467 10.716 8.34467 11.9993L8.33301 25.9994C8.33301 27.2827 9.38301 28.3327 10.6663 28.3327H29.333C30.6163 28.3327 31.6663 27.2827 31.6663 25.9994V14.3327C31.6663 13.0493 30.6163 11.9993 29.333 11.9993H19.9997L17.6663 9.66602Z"
        fill="#1ED367"
      />
      <defs>
        <clipPath id="clip0_2702_51752">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_2702_51752">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default LogLogo;
