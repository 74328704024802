// material-ui
import { SvgIcon, SvgIconTypeMap } from '@mui/material';
// ==============================|| AUTH BLUR BACK SVG ||============================== //

const AnalyticsLogo = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="33" height="20" viewBox="0 0 45 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 16.5C0 7.38798 7.38798 0 16.5 0C25.612 0 33 7.38798 33 16.5" fill="#91DCF9" />
      <path
        d="M3.10254 16.5003C3.10254 9.10174 9.10116 3.10352 16.4993 3.10352C23.8974 3.10352 29.896 9.10213 29.896 16.5003"
        fill="#094754"
      />
      <path
        d="M26.7673 3.58398L24.5557 5.79558C25.2847 6.34478 25.9556 6.96691 26.5578 7.65106L28.7554 5.45351C28.1453 4.77715 27.4803 4.1519 26.7673 3.58437V3.58398Z"
        fill="#1ED367"
      />
      <path d="M24.5557 5.79492C25.2847 6.34412 25.956 6.96625 26.5574 7.6504L24.5557 5.79492Z" fill="#91DCF9" />
      <path
        d="M26.5577 7.65079L17.7082 16.4999L16.6866 17.5215L14.7578 15.5926L24.5559 5.79492C25.2849 6.34412 25.9562 6.96625 26.5577 7.6504V7.65079Z"
        fill="#7DEBF5"
      />
      <path
        d="M16.4998 19.0787C18.1889 19.0787 19.5582 17.7094 19.5582 16.0203C19.5582 14.3312 18.1889 12.9619 16.4998 12.9619C14.8107 12.9619 13.4414 14.3312 13.4414 16.0203C13.4414 17.7094 14.8107 19.0787 16.4998 19.0787Z"
        fill="#F0FF32"
      />
      <path
        d="M8.18803 14.2286C9.09172 14.2286 9.8243 13.496 9.8243 12.5923C9.8243 11.6886 9.09172 10.9561 8.18803 10.9561C7.28434 10.9561 6.55176 11.6886 6.55176 12.5923C6.55176 13.496 7.28434 14.2286 8.18803 14.2286Z"
        fill="#F0FF32"
      />
      <path
        d="M11.4605 9.99617C12.3642 9.99617 13.0968 9.26359 13.0968 8.3599C13.0968 7.45622 12.3642 6.72363 11.4605 6.72363C10.5568 6.72363 9.82422 7.45622 9.82422 8.3599C9.82422 9.26359 10.5568 9.99617 11.4605 9.99617Z"
        fill="#F0FF32"
      />
      <path
        d="M16.4996 8.35945C17.4032 8.35945 18.1358 7.62687 18.1358 6.72318C18.1358 5.8195 17.4032 5.08691 16.4996 5.08691C15.5959 5.08691 14.8633 5.8195 14.8633 6.72318C14.8633 7.62687 15.5959 8.35945 16.4996 8.35945Z"
        fill="#F0FF32"
      />
    </svg>
  </SvgIcon>
);

export default AnalyticsLogo;
