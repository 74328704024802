// project import
import Default from './default';

// types
import { PaletteThemeProps } from 'types/theme';
import { PalettesProps } from '@ant-design/colors';
import { ThemeMode } from 'types/config';

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors: PalettesProps, mode: ThemeMode): PaletteThemeProps => {
  return Default(colors, mode);
};

export default Theme;
