import { Box, Pagination, useTheme } from '@mui/material';
import TasksLoading from 'components/custom/loading/TasksLoading';
import NoTasks from './NoTasks';
import SingleTask from './SingleTask';
import { PaginationType } from 'types/custom/pagination';
import { CountedData } from 'types/counted-data';
import { TaskDTO } from 'types/dto/task.dto';
import useLoader from 'hooks/useLoader';

interface Props {
  tasks?: CountedData<TaskDTO>;
  sorting: string;
  pageSize: number;
  setPagination: React.Dispatch<React.SetStateAction<PaginationType>>;
  isFetching: boolean;
}

export const TasksList = ({ tasks, pageSize, setPagination, sorting, isFetching }: Props) => {
  const theme = useTheme();
  const { isLoading } = useLoader();

  if (tasks && tasks.data.length === 0) {
    return <NoTasks />;
  }
  return (
    <Box className="standard-tasks-height vertical-standard-margin flex dir-col just-btwn">
      <Box border={`1px solid ${theme.palette.divider}`} borderRadius={4}>
        {isLoading || isFetching ? (
          <TasksLoading pageSize={pageSize} />
        ) : (
          tasks && tasks.data.map((task) => <SingleTask key={`task-${task.id}`} task={task} sorting={sorting} />)
        )}
      </Box>
      <Pagination
        onChange={(e, v) => {
          setPagination({ pageIndex: v - 1, pageSize: pageSize });
        }}
        count={tasks && tasks.filteredCount && Math.ceil(tasks && tasks.filteredCount / pageSize)}
        size="large"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
          pb: 2,
          '.MuiPaginationItem-root': { position: 'relative', zIndex: 1 }
        }}
      />
    </Box>
  );
};
