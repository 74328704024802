import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const CollaboratorLogo = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.49182 24.0001V13.0206C5.49182 11.8352 6.45284 10.8745 7.63791 10.8745H16.3621C17.5475 10.8745 18.5082 11.8355 18.5082 13.0206V24.0001H5.49182Z"
        fill="#094754"
      />
      <path
        d="M12.0003 15.5125C12.8428 15.5125 13.5257 14.8296 13.5257 13.9871C13.5257 13.1446 12.8428 12.4617 12.0003 12.4617C11.1578 12.4617 10.4749 13.1446 10.4749 13.9871C10.4749 14.8296 11.1578 15.5125 12.0003 15.5125Z"
        fill="#F0FF32"
      />
      <path
        d="M12.0003 10.8741C15.0031 10.8741 17.4374 8.43988 17.4374 5.43707C17.4374 2.43426 15.0031 0 12.0003 0C8.99749 0 6.56323 2.43426 6.56323 5.43707C6.56323 8.43988 8.99749 10.8741 12.0003 10.8741Z"
        fill="#7DEBF5"
      />
    </svg>
  </SvgIcon>
);

export default CollaboratorLogo;
