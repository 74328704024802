import { Box, Fade, Tooltip, TooltipProps, Typography, TypographyProps } from '@mui/material';

interface Props {
  children: React.ReactElement;
  isComplexContent?: boolean;
  content: React.ReactElement | string;
  bgColor?: string;
  color?: string;
  border?: string;
  placement?: TooltipProps['placement'];
  fontVariant?: TypographyProps['variant'];
  followCursor?: boolean;
  props?: any;
  disableHoverListener?: TooltipProps['disableHoverListener'];
  disableFocusListener?: TooltipProps['disableFocusListener'];
}
export const CustomTooltip = ({
  children,
  isComplexContent,
  content,
  bgColor,
  color,
  border,
  placement,
  fontVariant,
  followCursor,
  disableHoverListener,
  disableFocusListener,
  ...props
}: Props) => (
  <Tooltip
    arrow
    followCursor={followCursor}
    disableHoverListener={disableHoverListener}
    disableFocusListener={disableFocusListener}
    title={isComplexContent ? <Box>{content}</Box> : <Typography variant={fontVariant ?? 'body2'}>{content}</Typography>}
    placement={placement ?? 'bottom'}
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 300 }}
    PopperProps={{
      disablePortal: true,
      sx: { zIndex: 1501 }
    }}
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: bgColor ?? 'primary.main',
          color: color ?? 'white',
          border: border ?? 'none',
          '& .MuiTooltip-arrow': {
            color: bgColor ?? 'primary.main'
          }
        }
      }
    }}
    {...props}
  >
    {children}
  </Tooltip>
);
