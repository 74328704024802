import { Box, IconButton, InputBase, Paper, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { KeyboardEvent, useState } from 'react';
import { Comment } from 'types/dto/task.dto';
import { FormikErrors } from 'formik';
import { useEndpoint } from 'hooks/useEndpoint';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { queryClient } from 'App';

interface Props {
  taskID: number;
  comments: Comment[];
  setEditComments: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          commentsToSend: Comment[];
          editComments: {
            userID: number;
            edit: boolean;
            description: string;
          }[];
        }>
      >;
  init: (Comment & {
    edit: boolean;
  })[];
}

const AddComment = ({ taskID, comments, setEditComments, init }: Props) => {
  const theme = useTheme();
  const [currentComment, setCurrentComment] = useState<string>('');

  const addComment = useEndpoint<any, 'post'>({
    method: 'post',
    endpoint: `/tasks/${taskID}/comments`,
    mutationKey: `post-comment-on-task-${taskID}`,
    options: {
      onSuccess: () => {
        queryClient.refetchQueries(`get-task-${taskID}`);
        dispatch(
          openSnackbar({
            message: `Commento aggiunto con successo.`,
            open: true,
            variant: 'success',
            key: `add-comment-on-task-${taskID}`
          })
        );
        setCurrentComment('');
      }
    }
  });

  const handleAddComment = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, i: number) => {
    if (e.code === 'Enter' && currentComment !== '') {
      e.preventDefault();
      setEditComments('editComments', init);
      addComment.mutate({ description: e.currentTarget.value });
    }
    if (e.code === 'Enter' && currentComment === '') {
      e.preventDefault();
    }
  };

  const handleAddCommentClick = (i: number) => {
    if (currentComment !== '') {
      setEditComments('editComments', init);
      addComment.mutate({ description: currentComment });
    }
  };

  return (
    <Box mt={2}>
      <Paper
        component="form"
        sx={{
          display: 'flex',
          boxShadow: '0 0 0 0',
          border: `2px solid ${theme.palette.divider}`,
          alignItems: 'center',
          width: '100%'
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Aggiungi commento"
          value={currentComment}
          onChange={(e) => setCurrentComment(e.currentTarget.value)}
          inputProps={{ 'aria-label': 'add comment' }}
          onKeyDown={(e) => handleAddComment(e, comments.length + 1)}
        />
        <IconButton
          type="button"
          sx={
            currentComment !== ''
              ? { p: '10px' }
              : { p: '10px', color: theme.palette.action.selected, ':hover': { color: theme.palette.action.selected } }
          }
          aria-label="add-comment"
          onClick={() => handleAddCommentClick(comments.length + 1)}
        >
          <AddIcon />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default AddComment;
