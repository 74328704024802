import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface Props {
  type: 'user' | 'building' | 'order' | 'wallet';
  options: { serverProp: string; label: string }[];
  addVariable: ({ serverProp, label }: { serverProp: string; label: string }) => void;
}
export const VariableSelect = ({ type, options, addVariable }: Props) => {
  let variable = { serverProp: '', label: '' };

  return (
    <FormControl size="small" fullWidth sx={{ minWidth: 86 }}>
      <InputLabel id="unit">
        {type === 'user' ? 'Utente' : type === 'building' ? 'Struttura' : type === 'order' ? 'Ordine' : 'Wallet'}
      </InputLabel>
      <Select
        labelId="unit-select-label"
        id="unit-select"
        name="unit"
        value={variable?.serverProp}
        onChange={(e) => {
          addVariable({ serverProp: JSON.parse(e.target.value).sp, label: JSON.parse(e.target.value).label });
        }}
        label={type === 'user' ? 'Utente' : type === 'building' ? 'Struttura' : type === 'order' ? 'Ordine' : 'Wallet'}
        MenuProps={{ sx: { zIndex: 3000 } }}
      >
        <MenuItem value={''} sx={{ display: 'none' }} />

        {options.map((opt, i) => (
          <MenuItem key={`opt-${type}-${i}`} value={`{"sp":"${opt.serverProp}","label":"${opt.label}"}`}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
