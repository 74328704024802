import UserLogo from 'assets/images/icons/navigation/User';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types/menu';

const users: NavItemType = {
  id: 'users-group',
  title: '',
  type: 'group',
  children: [
    {
      id: 'users',
      title: <FormattedMessage id="users" />,
      type: 'collapse',
      icon: UserLogo,
      permissions: [{ resource: 'user', operations: ['read'] }],
      children: [
        {
          id: 'registered-users',
          title: <FormattedMessage id="registered-users" />,
          type: 'item',
          url: '/users'
        },
        {
          id: 'fast-registration-users',
          title: <FormattedMessage id="fast-registration-users" />,
          type: 'item',
          url: '/fast-registration-users'
        },
        {
          id: 'switcho-users',
          title: <FormattedMessage id="switcho-users" />,
          type: 'item',
          url: '/switcho-users'
        }
      ]
    }
  ]
};

export default users;
