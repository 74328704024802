import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material';

export const CalendarTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      maxWidth: 400,
      maxHeight: 500,
      padding: 1,
      boxShadow: `0 0 10px 2px ${theme.palette.action.selected}`
    }
  })
);
