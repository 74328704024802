import { TextareaAutosize, styled } from '@mui/material';

export const TodoTextarea = styled(TextareaAutosize)(
  () => `
		  width: 100%;
		  font-family: 'Work Sans', sans-serif;
		  font-size: 0.8rem;
		  font-weight: 400;
		  line-height: 1.5;
		  padding: 4px;
		  border-radius: 4px;
		  border: none;
		  resize: none;
		  &:disabled {
			  background-color: #FFFFFF;
			  color: #000000;
		  }
		  // firefox
		  &:focus-visible {
			outline: 0;
		  }
		  `
);
