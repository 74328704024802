import { useEffect } from 'react';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'store';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { authTokenLogin } = useAuth();
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (
      !isLoggedIn &&
      (localStorage['accessToken'] || sessionStorage['accessToken']) &&
      ((localStorage['accessToken'] !== '' && localStorage['accessToken'] !== undefined) ||
        (sessionStorage['accessToken'] !== '' && sessionStorage['accessToken'] !== undefined))
    ) {
      console.log('me sloggato AUTH');
      authTokenLogin();
    } else if (
      !isLoggedIn &&
      (localStorage['accessToken'] === '' ||
        localStorage['accessToken'] === undefined ||
        sessionStorage['accessToken'] === '' ||
        sessionStorage['accessToken'] === undefined)
    ) {
      console.log('ti butto al login');
      navigate('/login', { replace: true });
    } else if (
      isLoggedIn &&
      state?.previous !== '/' &&
      (localStorage['accessToken'] || sessionStorage['accessToken']) &&
      (localStorage['accessToken'] !== '' || sessionStorage['accessToken'] !== '')
    ) {
      console.log('me loggato AUTH');

      authTokenLogin();
    }
  }, [isLoggedIn, localStorage['accessToken'], sessionStorage['accessToken']]);

  return isLoggedIn && user != null ? children : <></>;
};

export default AuthGuard;
