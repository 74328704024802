import { FormattedMessage } from 'react-intl';

// assets
import DashboardLogo from 'assets/images/icons/navigation/Dashboard';
import AnalyticsLogo from 'assets/images/icons/navigation/Analytics';
import OrdersLogo from 'assets/images/icons/navigation/Orders';
import SettingsLogo from 'assets/images/icons/navigation/Settings';
import BuildingsLogo from 'assets/images/icons/navigation/buildings';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  DashboardLogo,
  AnalyticsLogo,
  OrdersLogo,
  BuildingsLogo,
  SettingsLogo
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const analytics: NavItemType = {
  id: 'analytics',
  title: '',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardLogo
    },
    {
      id: 'analytics',
      title: <FormattedMessage id="analytics" />,
      type: 'item',
      icon: icons.AnalyticsLogo,
      url: '/analytics'
    },
    {
      id: 'orders',
      title: <FormattedMessage id="orders" />,
      type: 'item',
      icon: icons.OrdersLogo,
      permissions: [{ resource: 'order', operations: ['read'] }],
      url: '/orders'
    },
    {
      id: 'buildings',
      title: <FormattedMessage id="buildings" />,
      type: 'item',
      icon: BuildingsLogo,
      permissions: [
        { resource: 'building', operations: ['read'] },
        { resource: 'user', operations: ['delete'] }
      ],
      url: '/buildings'
    }
  ]
};

export default analytics;
