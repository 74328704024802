import LogLogo from 'assets/images/icons/navigation/LogLogo';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const log: NavItemType = {
  id: 'log',
  title: '',
  type: 'group',
  children: [
    {
      id: 'log',
      title: 'Log',
      type: 'item',
      icon: LogLogo,
      permissions: [{ resource: null, operations: ['read', 'create', 'update', 'delete'] }],
      url: '/log'
    }
  ]
};

export default log;
