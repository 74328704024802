export const documentsFormat = ['application/pdf', 'image/jpg', 'image/jpeg', 'application/doc', 'application/docx'];

export const taskDocumentsFormat = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'];

export const contractFormat = ['application/pdf'];

export const imageFormat = ['image/jpg', 'image/jpeg', 'image/png'];

export const iconFormat = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml'];

export const viewFormats = (formats: string): string => {
  const result = [];
  if (formats.includes('pdf')) {
    result.push('PDF');
  }
  if (formats.includes('jpg' || 'jpeg')) {
    result.push('JPEG');
  }
  if (formats.includes('png')) {
    result.push('PNG');
  }
  if (formats.includes('doc' || 'docx')) {
    result.push('DOC');
  }
  return result.join(', ');
};
