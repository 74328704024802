import { Box, Typography } from '@mui/material';
import { SendTaskDTO } from 'types/dto/task.dto';
import { ColoredInstantSelectStatus } from 'components/custom/status/select/ColoredInstantSelectStatus';
import { taskStatusList } from 'components/custom/status/statusList/task';
import { humanizeDate } from 'utils/formattation';
import { StyledTitleTextarea } from 'components/custom/styledComponents';
import { FormikErrors } from 'formik';

interface Props {
  values: SendTaskDTO;
  setValues: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          data: SendTaskDTO | undefined;
        }>
      >;
  canEdit: boolean;
}
const TitleAndStatus = ({ values, setValues }: Props) => {
  return (
    <Box className="flex just-center dir-col vertical-standard-margin">
      <Box className="flex ali-center just-btwn">
        <Box className="flex ali-start just-btwn vertical-standard-margin" width="100%">
          <Box className="flex ali-start" flexGrow={1}>
            <Box className="flex dir-col just-start ali-start" flexGrow={1} ml={-1}>
              <StyledTitleTextarea minRows={1} value={values.title} onChange={async (e) => await setValues('data.title', e.target.value)} />
            </Box>
          </Box>
          <ColoredInstantSelectStatus
            chipSize="small"
            initialStatus={values.state!}
            sendData={({ state }) => setValues('data.state', state)}
            statusList={taskStatusList}
          />
        </Box>
      </Box>
      <Typography variant="caption" fontStyle="italic" className="textSecondary">
        Ultimo aggiornamento: {values.lastEdit && humanizeDate(values.lastEdit)}
      </Typography>
    </Box>
  );
};

export default TitleAndStatus;
