import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import PermissionsGuard from 'utils/route-guard/PermissionsGuard';
import Error from 'pages/error';
import { WebsocketProvider } from 'components/custom/Websocket';
import { Prova } from 'pages/prova';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('pages/dashboard/Dashboard')));
// Analytics
const Analytics = Loadable(lazy(() => import('pages/analytics/Analytics')));

// Collaborators
const Collaborators = Loadable(lazy(() => import('pages/collaborators/Collaborators')));
const NewCollaborator = Loadable(lazy(() => import('pages/collaborators/NewCollaborator')));

const CollaboratorAccount = Loadable(lazy(() => import('sections/collaborators/Account')));
// Buildings
const Buildings = Loadable(lazy(() => import('pages/buildings/Buildings')));
const Building = Loadable(lazy(() => import('pages/buildings/Building')));
// Orders
const Orders = Loadable(lazy(() => import('pages/orders/Orders')));
const Order = Loadable(lazy(() => import('pages/orders/Order')));
// Users
const FastUsers = Loadable(lazy(() => import('pages/users/fastUsers')));
const SwitchoUsers = Loadable(lazy(() => import('pages/users/switchoUsers')));
const Users = Loadable(lazy(() => import('pages/users/Users')));
const UserAccount = Loadable(lazy(() => import('sections/user/Account')));

// Referral codes
const ReferralCodes = Loadable(lazy(() => import('pages/referral-codes/ReferralCodes')));

// Faqs
const Faqs = Loadable(lazy(() => import('pages/faq/all-faqs')));

// Partners
const Partners = Loadable(lazy(() => import('pages/partners/all-partners')));
const AllDocuments = Loadable(lazy(() => import('pages/documents/all-documents')));

// Impostazioni
const Settings = Loadable(lazy(() => import('pages/settings/Settings')));

//Log
const Logs = Loadable(lazy(() => import('pages/log/all-logs')));

//Log
const Autopilot = Loadable(lazy(() => import('pages/autopilot')));
const NewTarget = Loadable(lazy(() => import('pages/autopilot/NewTarget')));
const NewFlux = Loadable(lazy(() => import('pages/autopilot/NewFlux')));
const DeleteNode = Loadable(lazy(() => import('pages/autopilot/DeleteNode')));
const CreateEvent = Loadable(lazy(() => import('pages/autopilot/CreateEvent')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <WebsocketProvider>
          <AuthGuard>
            <PermissionsGuard>
              <MainLayout />
            </PermissionsGuard>
          </AuthGuard>
        </WebsocketProvider>
      ),
      children: [
        {
          path: 'error',
          element: <Error />
        },
        { path: 'prova', element: <Prova /> },
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'analytics',
          element: <Analytics />
        },
        {
          path: 'collaborators',
          element: <Collaborators />,
          children: [
            {
              path: 'new',
              element: <NewCollaborator />
            },
            {
              path: ':id/:fullname',
              element: <CollaboratorAccount />
            },
            {
              path: 'me',
              element: <CollaboratorAccount />
            }
          ]
        },
        {
          path: 'users',
          element: <Users />,
          children: [
            {
              path: ':id/:fullname',
              element: <UserAccount />
            }
          ]
        },
        {
          path: 'switcho-users',
          element: <SwitchoUsers />
        },
        {
          path: 'fast-registration-users',
          element: <FastUsers />
        },
        {
          path: 'orders',
          element: <Orders />,
          children: [
            {
              path: ':buildingId/:buildingName/:orderId',
              element: <Order />
            }
          ]
        },
        {
          path: 'buildings',
          element: <Buildings />,
          children: [
            {
              path: ':buildingId/:buildingName',
              element: <Building />
            }
          ]
        },
        { path: 'referral-codes', element: <ReferralCodes /> },
        { path: 'faq', element: <Faqs /> },
        { path: 'settings', element: <Settings /> },
        { path: 'partners', element: <Partners /> },
        { path: 'platform-contracts', element: <AllDocuments /> },
        { path: 'log', element: <Logs /> },
        {
          path: 'autopilot',
          element: <Autopilot />,
          children: [
            // il componente NewTarget è riutilizzabile, la differenza è nel fatto che il flusso è già stato creato o meno
            { path: 'create-target-preset', element: <NewTarget /> },
            { path: 'target/:targetID', element: <NewTarget /> },
            // il componente NewFlux è riutilizzabile, la differenza è nel fatto che il flusso è già stato creato o meno
            { path: 'create-flux', element: <NewFlux /> },
            { path: ':fluxID', element: <NewFlux /> },
            { path: 'create-event', element: <CreateEvent /> },
            { path: 'delete-node', element: <DeleteNode /> }
          ]
        },
        {
          path: '/maintenance',
          element: <CommonLayout />,
          children: [
            {
              path: '404',
              element: <MaintenanceError />
            },
            {
              path: '500',
              element: <MaintenanceError500 />
            },
            {
              path: 'under-construction',
              element: <MaintenanceUnderConstruction />
            },
            {
              path: 'coming-soon',
              element: <MaintenanceComingSoon />
            }
          ]
        }
      ]
    }
  ]
};

export default MainRoutes;
