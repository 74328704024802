import { SortingProps } from './types/sorting-props';

export const formatSorting = (sorting: SortingProps[]) => {
  return JSON.stringify(
    sorting.map((el: any) => ({
      field: el.id,
      sort: el.desc ? 'desc' : 'asc'
    }))
  );
};
