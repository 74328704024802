// ==============================|| PERMISSIONS GUARD ||============================== //

import { Box, CircularProgress } from '@mui/material';
import useAuth from 'hooks/useAuth';
import Unauthorized from 'pages/maintenance/unauthorized';
import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router';
import { dispatch, useSelector } from 'store';
import { authOk } from 'store/reducers/authorization';
const PermissionsGuard = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const auth = useAuth();
  const isAuth = useSelector((state) => state.authorization.isAuth);
  const location = useLocation();

  useLayoutEffect(() => {
    setTimeout(() => {
      document.getElementById('bootstrap-loader')?.classList.add('remove');
      setTimeout(() => {
        document.getElementById('bootstrap-loader')?.remove();
      }, 200);
    }, 1000);
  }, []);

  useEffect(() => {
    dispatch(authOk());
  }, [location]);

  return isAuth && auth ? (
    <>{children}</>
  ) : isAuth === false ? (
    <Unauthorized />
  ) : (
    <Box width="100%" height="100vh" display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );
};

export default PermissionsGuard;
