// material-ui

// project import
import Profile from './Profile';
import Notification from 'components/custom/navigationBar/NotificationButton';
import Task from 'components/custom/navigationBar/TaskButton';
import Note from 'components/custom/navigationBar/NoteButton';
import HeaderBreadcrumb from 'components/custom/HeaderBreadcrumb';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { dispatch } from 'store';
import { setNotesCount } from 'store/reducers/helpers';
import { useLocation, useParams } from 'react-router';
import { useEndpoint } from 'hooks/useEndpoint';
import { CountedData } from 'types/counted-data';
import { NoteDTO } from 'types/dto/note.dto';
import { resourceSelector } from 'utils/filtersUtils/resourceSelector';
import { formatBackOrder } from 'utils/formattation';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { pathname } = useLocation();
  const { orderId, buildingId, id } = useParams();

  const [subjectToSend, setSubjectToSend] = useState<string[]>([]);
  const [sectionFilter, setSectionFilter] = useState<string | null>(resourceSelector({ location: pathname }));

  const getNotes = useEndpoint<CountedData<NoteDTO>, 'get'>({
    method: 'get',
    endpoint: '/notes',
    queryKey: 'get-notes',
    queryParams: {
      filterBy: JSON.stringify(
        [
          sectionFilter ? { field: 'resourceSlug', value: { equals: sectionFilter } } : undefined,
          subjectToSend.length ? { field: 'subjects', value: { equals: subjectToSend } } : undefined
        ].filter((r) => r)
      )
    },
    options: {
      onSuccess: ({ data }) => {
        dispatch(setNotesCount(data.filteredCount));
      }
    }
  });

  useEffect(() => {
    setSectionFilter(resourceSelector({ location: pathname }));
  }, [pathname]);

  useEffect(() => {
    getNotes.refetch();
  }, [pathname, sectionFilter, subjectToSend]);

  useEffect(() => {
    orderId
      ? setSubjectToSend([orderId])
      : buildingId
      ? setSubjectToSend([formatBackOrder(buildingId)])
      : id
      ? setSubjectToSend([formatBackOrder(id)])
      : setSubjectToSend([]);
  }, [orderId, buildingId, id]);

  return (
    <>
      <Box display="flex" flexGrow={1}>
        <HeaderBreadcrumb />
      </Box>

      <Task />
      <Note />
      <Profile />
      <Notification />
    </>
  );
};

export default HeaderContent;
