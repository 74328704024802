// project import
import { Box } from '@mui/material';
import { dispatch, useSelector } from 'store';
import { setRightDrawerOpen } from 'store/reducers/menu';
import { useEffect, useState } from 'react';
import { PaginationType } from 'types/custom/pagination';
import { Subject } from 'types/components/subjectFilter';
import { Tasks } from './tasks';
import { useEndpoint } from 'hooks/useEndpoint';
import { CountedData } from 'types/counted-data';
import { TaskDTO } from 'types/dto/task.dto';
import { setNotesCount, setTasksCount } from 'store/reducers/helpers';
import { Task } from './task';
import { Note } from './note';
import { NoteDTO } from 'types/dto/note.dto';
import { Notifications } from './notifications';
import { useWindowLayout } from 'hooks/useWindowHeight';
import { useLocation, useParams } from 'react-router';
import { resourceObjectSelector } from 'utils/filtersUtils/resourceSelector';
import { formatBackOrder } from 'utils/formattation';

let availableTasksHeight = 0;
let availableNotesHeight = 0;
export const DrawerContent = () => {
  const { pathname } = useLocation();
  const { orderId, id, fullname, buildingId, buildingName } = useParams();
  const { windowHeight } = useWindowLayout();
  const responsiveTasksPageSize = Math.floor(availableTasksHeight / 52);
  const responsiveNotesPageSize = Math.floor(availableNotesHeight / 122);

  const { activeRightItem, rightDrawerOpen, taskId } = useSelector((state) => state.menu);
  const { tasksCount } = useSelector((state) => state.helpers);
  const [subject, setSubject] = useState<Subject>({ serverData: [], label: '' });
  const [notesSearchFilter, setNotesSearchFilter] = useState<string>();
  const [tasksPagination, setTasksPagination] = useState<PaginationType>({ pageIndex: 0, pageSize: responsiveTasksPageSize });
  const [notesPagination, setNotesPagination] = useState<PaginationType>({ pageIndex: 0, pageSize: responsiveNotesPageSize });
  const [sectionFilter, setSectionFilter] = useState<string | undefined | null>(null);
  const [tasksStateFilter, setTasksStateFilter] = useState<{
    field: ['state', 'key'];
    value: { equals: 'created' | 'pending' | 'paused' | 'expired' | 'completed' | 'deleted' } | undefined;
  }>();
  const [showUnreadTasks, setShowUnreadTasks] = useState<{ field: 'lastReadAt'; value: { equals: null } }>();
  const [tasksSorting, setTasksSorting] = useState<string>('{ "field": "lastEdit", "sort": "desc" }');

  //dadefinire
  console.log('notes search filter', notesSearchFilter);
  const handleCloseDrawer = () => {
    dispatch(setRightDrawerOpen(false));
  };
  const filtersSum = [
    sectionFilter === 'all'
      ? tasksStateFilter
        ? { field: 'state.key', value: { equals: tasksStateFilter } }
        : undefined
      : { field: 'resourceSlug', value: { equals: sectionFilter ?? null } },
    showUnreadTasks,
    tasksStateFilter,
    subject.serverData.length ? { field: 'subjects', value: { equals: subject.serverData } } : undefined
  ].filter((r) => r);

  const getTasks = useEndpoint<CountedData<TaskDTO>, 'get'>({
    method: 'get',
    endpoint: '/tasks',
    queryKey: 'get-all-tasks',
    queryParams: {
      orderBy: JSON.stringify([JSON.parse(tasksSorting)]),
      filterBy: activeRightItem === 'task' ? undefined : filtersSum.length ? JSON.stringify(filtersSum) : undefined,
      skip: tasksPagination.pageIndex * tasksPagination.pageSize,
      take: tasksPagination.pageSize
    },
    options: {
      enabled: !tasksCount || (rightDrawerOpen && tasksPagination.pageSize > 0),
      onSuccess: ({ data }) => {
        dispatch(setTasksCount(data.filteredCount ?? 0));
      }
    }
  });

  const tasks = getTasks.data?.data;

  const getNotes = useEndpoint<CountedData<NoteDTO>, 'get'>({
    method: 'get',
    endpoint: '/notes',
    queryKey: 'get-all-notes',
    queryParams: {
      filterBy:
        activeRightItem === 'task'
          ? undefined
          : JSON.stringify(
              [
                sectionFilter === undefined
                  ? undefined
                  : sectionFilter === 'all'
                  ? undefined
                  : { field: 'resourceSlug', value: { equals: sectionFilter } },
                subject.serverData.length ? { field: 'subjects', value: { equals: subject.serverData } } : undefined
              ].filter((r) => r)
            ),
      skip: notesPagination.pageIndex * notesPagination.pageSize,
      take: responsiveNotesPageSize
    },
    options: {
      enabled: rightDrawerOpen && notesPagination.pageSize > 0,
      onSuccess: ({ data }) => {
        dispatch(setNotesCount(data.filteredCount));
      }
    }
  });
  const notes = getNotes.data?.data;

  useEffect(() => {
    setSectionFilter(resourceObjectSelector(pathname)?.slug);
  }, [pathname]);

  useEffect(() => {
    if (activeRightItem === 'tasks' || activeRightItem === 'note') {
      setSubject(
        orderId
          ? { serverData: [orderId], label: orderId }
          : id && fullname
          ? { serverData: [id], label: formatBackOrder(fullname) }
          : buildingId && buildingName
          ? { serverData: [buildingId], label: formatBackOrder(buildingName) }
          : { serverData: [], label: '' }
      );
    }
  }, [activeRightItem, pathname]);

  useEffect(() => {
    if (windowHeight) {
      availableTasksHeight = windowHeight - 320;
      setTasksPagination({ pageIndex: 0, pageSize: responsiveTasksPageSize });
    }
  }, [windowHeight, availableTasksHeight]);

  useEffect(() => {
    if (windowHeight) {
      availableNotesHeight = windowHeight - 320;
      setNotesPagination({ pageIndex: 0, pageSize: responsiveNotesPageSize });
    }
  }, [windowHeight, availableNotesHeight]);

  return (
    <Box className="right-drawer-container">
      <div style={{ width: '100%' }} className={rightDrawerOpen && activeRightItem === 'tasks' ? 'show' : 'hide'}>
        <Tasks
          tasks={tasks}
          showUnread={showUnreadTasks}
          setShowUnread={setShowUnreadTasks}
          isFetching={getTasks.isFetching}
          closeDrawer={handleCloseDrawer}
          pageSize={tasksPagination.pageSize}
          setPagination={setTasksPagination}
          sectionFilter={sectionFilter}
          setStateFilter={setTasksStateFilter}
          setSectionFilter={setSectionFilter}
          sorting={tasksSorting}
          setSorting={setTasksSorting}
          subject={subject}
          setSubject={setSubject}
        />
      </div>
      {rightDrawerOpen && activeRightItem === 'task' && (
        <Task key={taskId ?? 1} closeDrawer={handleCloseDrawer} subject={subject} setSubject={setSubject} />
      )}
      {rightDrawerOpen && activeRightItem === 'new-task' && (
        <Task
          isNewTask
          key={taskId ?? 1}
          closeDrawer={handleCloseDrawer}
          subject={subject}
          setSubject={setSubject}
          sectionFilter={sectionFilter}
          setSectionFilter={setSectionFilter}
        />
      )}
      {rightDrawerOpen && activeRightItem === 'note' && (
        <Note
          notes={notes}
          closeDrawer={handleCloseDrawer}
          subject={subject}
          setSubject={setSubject}
          resource={sectionFilter}
          setResource={setSectionFilter}
          setSearchFilter={setNotesSearchFilter}
          pageSize={notesPagination.pageSize}
          setPagination={setNotesPagination}
          isFetching={getNotes.isFetching}
        />
      )}
      {rightDrawerOpen && activeRightItem === 'notification' && <Notifications closeDrawer={handleCloseDrawer} />}
    </Box>
  );
};
