import { LangTextDto } from './lang-text.dto';

export interface NotificationType {
  id: number;
  userHolderId: number | null;
  tag: NotifyTag;
  user: User;
  operation: 'create' | 'update' | 'delete';
  subject: string;
  old: any;
  new: any;
  customMessage: string | null;
  description: LangTextDto | null;
  subjectName: string;
  severity: string;
  resource: string;
  environmentKey: 'server' | 'api';
  readAt: string | null;
  at: string;
  userNotificationNotices: NotificationNotice[];
}

export interface User {
  id: number;
  firstname: string;
  lastname: string;
}

export interface NotificationNotice {
  userId: number;
  readAt: string;
}

export enum NotifyTag {
  Wallet = 'wallet', //Dentro Building
  PmsExports = 'pms-exports', //Dentro Building
  Contact = 'contact', //dentro User
  Document = 'document', //Dentro ordine o building fa riferimento ai documenti,
  BillingInfo = 'billing-infos'
}
