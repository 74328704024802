import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const Support = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 23.0806L4.9827 17.2935H1V23.0806Z" fill="#91DCF9" />
      <path d="M20.7243 1H1V14.4287H6.95411L4.9827 17.2934H24V4.2757C24 2.46652 22.5335 1 20.7243 1Z" fill="#094754" />
      <path
        d="M7.8875 10.4901C8.71818 10.4901 9.39157 9.81669 9.39157 8.98601C9.39157 8.15533 8.71818 7.48193 7.8875 7.48193C7.05682 7.48193 6.38342 8.15533 6.38342 8.98601C6.38342 9.81669 7.05682 10.4901 7.8875 10.4901Z"
        fill="#F0FF32"
      />
      <path
        d="M12.5113 10.4901C13.342 10.4901 14.0154 9.81669 14.0154 8.98601C14.0154 8.15533 13.342 7.48193 12.5113 7.48193C11.6806 7.48193 11.0072 8.15533 11.0072 8.98601C11.0072 9.81669 11.6806 10.4901 12.5113 10.4901Z"
        fill="#F0FF32"
      />
      <path
        d="M17.2488 10.4901C18.0795 10.4901 18.7529 9.81669 18.7529 8.98601C18.7529 8.15533 18.0795 7.48193 17.2488 7.48193C16.4181 7.48193 15.7448 8.15533 15.7448 8.98601C15.7448 9.81669 16.4181 10.4901 17.2488 10.4901Z"
        fill="#F0FF32"
      />
      <path d="M1 14.4287V17.2934H4.9827L6.95411 14.4287H1Z" fill="#49EBAA" />
    </svg>
  </SvgIcon>
);

export default Support;
