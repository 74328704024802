import { Box, Fade } from '@mui/material';

const Transition = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <Fade in>
      <Box>{children}</Box>
    </Fade>
  );
};

export default Transition;
