import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Box, Fade, Grow, IconButton, Slide, SlideProps, Typography } from '@mui/material';
// import MuiSnackbar from '@mui/material/Snackbar';
// project-import
// import IconButton from './IconButton';
// assets
// import { CloseOutlined } from '@ant-design/icons';
import { KeyedObject, RootStateProps } from 'types/root';
// import { closeSnackbar as closeSnackbarReducer } from 'store/reducers/snackbar';
import { useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import { closeSnackbar } from 'store/reducers/snackbar';

// animation function
function TransitionSlideLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
  return <Grow {...props} />;
}

// animation options
const animation: KeyedObject = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state: RootStateProps) => state.snackbar);
  const { message, open, transition, variant, key } = snackbar;

  const { enqueueSnackbar, closeSnackbar: closeNotistack } = useSnackbar();

  const handleClose = () => {
    dispatch(closeSnackbar(key));
  };

  const CustomComponent = () => {
    return (
      <Box width={250} className="flex just-btwn ali-center">
        {Array.isArray(message) ? (
          <>
            {message.map((item, index) => (
              <Typography key={index}>
                {message.length > 1 ? '• ' : ''}
                {item}
              </Typography>
            ))}
          </>
        ) : (
          <Typography variant="body2" px={0.5} fontWeight={500}>
            {message}
          </Typography>
        )}
        {(variant === 'warning' || variant === 'error') && (
          <Box height={30} width={30} className="flex just-center ali-center">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (open) {
      enqueueSnackbar(<CustomComponent />, {
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        TransitionComponent: transition != null ? animation[transition] : animation.Fade,
        variant,
        onClose: handleClose,
        autoHideDuration: 2500,
        persist: variant === 'warning' || variant === 'error',
        key
      });
    } else {
      key && closeNotistack(key);
    }
  }, [key, open]);

  return <></>;
};

export default Snackbar;
