export enum OrderState {
  Draft = 'draft', // In bozza
  Filled = 'filled', // Compilazione completata e non più modificabile, quindi in attesa di conferma di un operatore
  Refused = 'refused',
  UserApproved = 'user-approved',
  AdminProcessing = 'admin-processing',
  AdminPublished = 'admin-published',
  AdminRefused = 'admin-refused',
  Approved = 'accepted',
  IssuedInvoice = 'issued-invoice',
  DepositPaid = 'deposit-paid',
  Setup = 'setup',
  InProgress = 'in-progress', // Ordine spedito - Servizio in corso
  Completed = 'completed' // Ordine consegnato - Servizio terminato
}
