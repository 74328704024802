import CollaboratorsLogo from 'assets/images/icons/navigation/Collaborator';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types/menu';

const collaborators: NavItemType = {
  id: 'collaborators-group',
  title: '',
  type: 'group',
  children: [
    {
      id: 'collaborators',
      title: <FormattedMessage id="collaborators" />,
      type: 'collapse',
      icon: CollaboratorsLogo,
      children: [
        {
          id: 'all-collaborators',
          title: <FormattedMessage id="all-collaborators" />,
          type: 'item',
          url: '/collaborators',
          permissions: [{ resource: 'user', operations: ['read'] }]
        },
        {
          id: 'new-collaborator',
          title: <FormattedMessage id="new-collaborator" />,
          type: 'item',
          url: '/collaborators/new',
          permissions: [{ resource: 'user', operations: ['read', 'create'] }]
        }
      ]
    }
  ]
};

export default collaborators;
