import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/reducers/snackbar';
import useAuth from './useAuth';
import * as process from 'process';
import { AlertColor } from '@mui/material';

const axiosErrors = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + 'server/' + process.env.REACT_APP_API_VERSION
});
axiosErrors.defaults.headers.post['Content-Type'] = 'application/json';

/*
 * HOOK PER GESTIRE TUTTI GLI ERRORI DI OGNI CHIAMATA AXIOS
 */
export const useError = () => {
  const dispatch = useDispatch();

  const { logout } = useAuth();

  const handleError = (error: any) => {
    let errorVariant: AlertColor = 'error',
      message: string = "C'è stato un errore nel caricamento della risorsa.";

    switch (error.response?.status) {
      case 404:
        message = 'Risorsa non trovata.';
        errorVariant = 'error';
        break;
      case 400:
        message = Array.isArray(error.response.data.message) ? error.response.data.message : 'Dati inviati non corretti.';
        errorVariant = 'warning';
        break;
      case 401:
        localStorage['token'] = 'null';
        localStorage['auth'] = 'false';
        logout();
        break;
      case 403:
        message = 'Non sei autorizzato.';
        errorVariant = 'error';
        break;
      case 409:
        message = 'Un utente ha già modificato questa sezione, aggiorna la pagina.';
        errorVariant = 'warning';
        break;
      case 500:
        message = "C'è stato un errore del server, i tecnici sono già al lavoro per risolvere il problema.";
        errorVariant = 'error';
        break;
      case 502:
        message = "C'è stato un errore di un sistema collegato, i tecnici sono già al lavoro per risolvere il problema.";
        errorVariant = 'error';
        break;

      default:
        break;
    }

    dispatch(
      openSnackbar({
        open: true,
        message,
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        variant: errorVariant,
        key: `error-`
      })
    );
  };
  const sendErrorClient = (error?: Error) => {
    axiosErrors({
      method: 'post',
      headers: {
        'gcgyh-cwczz': '6E8JKy0DF8AJz20Kqzl4'
      },
      url: '/errors',
      data: {
        message: error?.message,
        stack: error?.stack,
        agent: navigator.userAgent,
        at: dayjs().toDate(),
        localStorage: JSON.stringify(localStorage),
        sessionStorage: JSON.stringify(sessionStorage)
      }
    })
      .then((res) => {
        console.log('Errore inviato: ', error);
      })
      .catch((err) => {
        console.log('Errore non inviato: ', error);
      });
  };

  return { handleError, sendErrorClient };
};
