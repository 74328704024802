import { CronJobName } from 'types/dto/log.dto';

export const translatePath = (path: string) => {
  let result = '';

  path === '/complete-fast-register'
    ? (result = 'Completa registrazione fast')
    : path === '/building-register'
    ? (result = 'Registrazione dati struttura')
    : path === '/building-register/manual-integration'
    ? (result = 'Integrazione manuale')
    : path === '/building-register/report-integration'
    ? (result = 'Integrazione report')
    : path === '/building-register/report-integration/step-1'
    ? (result = 'Integrazione report - step 1')
    : path === '/building-register/report-integration/step-2'
    ? (result = 'Integrazione report - step 2')
    : path === '/building-register/report-integration/step-3'
    ? (result = 'Integrazione report - step 3')
    : path === '/building-register/report-integration/step-4'
    ? (result = 'Integrazione report - step 4')
    : path === '/building-register/manual-integration/step-1'
    ? (result = 'Integrazione manuale - step 1')
    : path === '/building-register/manual-integration/step-2'
    ? (result = 'Integrazione manuale - step 2')
    : path === '/building-register/manual-integration/step-3'
    ? (result = 'Integrazione manuale - step 3')
    : path === '/building-register/manual-integration/step-4'
    ? (result = 'Integrazione manuale - step 4')
    : path === '/dashboard'
    ? (result = 'Dashboard')
    : path === '/wallet'
    ? (result = 'Wallet')
    : path === '/contacts'
    ? (result = 'Contatti')
    : path === '/notifications'
    ? (result = 'Notifiche')
    : path === '/account/settings'
    ? (result = 'Profilo')
    : path === '/account/documents'
    ? (result = 'Profilo -> Documenti')
    : path === '/support/faqs'
    ? (result = 'Supporto -> FAQ')
    : path === '/support/ticket'
    ? (result = 'Supporto -> Ticket')
    : path === '/support/help-center'
    ? (result = 'Supporto -> Help center')
    : path === '/account/settings/address'
    ? (result = 'Profilo -> indirizzi di spedizione')
    : path === '/account/settings/billing'
    ? (result = 'Profilo -> dati di fatturazione')
    : path === '/account/settings/privacy'
    ? (result = 'Profilo -> privacy')
    : path === '/orders'
    ? (result = 'Lista ordini')
    : path === '/orders/new-order'
    ? (result = 'Nuovo ordine')
    : path.includes('/orders/new-order/')
    ? (result = `Nuovo ordine ${path.split('/')[3]}`)
    : path.includes('/orders/')
    ? (result = `Ordine ${path.split('/')[2]}`)
    : (result = path);
  return result;
};

export const translateCronJob = (name: CronJobName) => {
  switch (name) {
    case CronJobName.ProcessActivateWallets:
      return 'Attivazione plafond';
    case CronJobName.ProcessCheckKnownLocations:
      return 'Controllo posizione località sulla mappa';
    case CronJobName.ProcessEmails:
      return 'Invio email';
    case CronJobName.ProcessExpireOrder:
      return 'Scadenza dei termini per la valutazione di un ordine';
    case CronJobName.ProcessTakeRiskData:
      return 'Recupero dei dati di rischio da Credit Safe';
    default:
      return 'Crea notifica';
  }
};

export const selectCronJobMessage = (data: any) => {
  if (data?.orderCode) {
    return `- ${data.orderCode}`;
  }
  if (data?.vat && data?.buildingId) {
    return `- struttura ${data.buildingId}, P.IVA ${data.vat}`;
  }
  if (data?.buildingId) {
    return `- struttura ${data.buildingId}`;
  }
  if (data?.lat && data?.lng && data?.buildingId) {
    return `Struttura ${data.buildingId}, lat: ${data.lat} - lon: ${data.lon}`;
  }
  if (data?.userId) {
    return `Utente ${data.userId}`;
  }
  if (data?.to && data?.emailSubject) {
    return `a ${data.to}, oggetto: ${data.emailSubject}`;
  }
};

export const translateCategory = (cat: string) => {
  switch (cat) {
    case 'user':
      return 'Utente';
    case 'building':
      return 'Struttura';
    default:
      return 'Ordine';
  }
};
