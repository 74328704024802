import { Box } from '@mui/material';
import { TasksProps } from 'types/task/tasksProps';
import Header from './Header';
import { TaskAndSearch } from './TaskAndSearch';
import { TasksFilters } from './tasksFilters';
import { TasksList } from './TasksList';
import useLoader from 'hooks/useLoader';

export const Tasks = ({
  closeDrawer,
  tasks,
  sorting,
  setSorting,
  pageSize,
  setPagination,
  setStateFilter,
  sectionFilter,
  setSectionFilter,
  subject,
  setSubject,
  isFetching,
  showUnread,
  setShowUnread
}: TasksProps) => {
  const { isLoading } = useLoader();

  return (
    <>
      <Box className={`right-drawer-el-container ${isLoading || isFetching ? 'hide' : 'show'}`}>
        <Header
          onClose={closeDrawer}
          resource={sectionFilter}
          setResource={setSectionFilter}
          subject={subject}
          setSubject={setSubject}
          setStateFilter={setStateFilter}
        />
        <Box className="standard-margin-container">
          <TaskAndSearch />
          <TasksFilters
            sorting={sorting}
            setSorting={setSorting}
            setStateFilter={setStateFilter}
            showUnread={showUnread}
            setShowUnread={setShowUnread}
          />
          <TasksList tasks={tasks} isFetching={isFetching} sorting={sorting} pageSize={pageSize} setPagination={setPagination} />
        </Box>
      </Box>
    </>
  );
};
