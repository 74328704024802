import { Suspense, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar, Typography, Button, Fade, Grid } from '@mui/material';

// project import
import NavDrawer from './Drawer';
import Header from './Header';
// import HorizontalBar from './Drawer/HorizontalBar';
// import Breadcrumbs from 'components/@extended/Breadcrumbs';

import useConfig from 'hooks/useConfig';
import { openDrawer, setRightDrawerOpen } from 'store/reducers/menu';

// types
import { RootStateProps } from 'types/root';
import { FallbackProps, withErrorBoundary } from 'react-error-boundary';
import { useError } from 'hooks/useError';
import IllustrationError from 'assets/images/illustrations/illustration_error.svg';
import Rotellina1 from 'assets/images/illustrations/rotellina_1.svg';
import Rotellina2 from 'assets/images/illustrations/rotellina_2.svg';
import { setFetchNotification } from 'store/reducers/helpers';
import { openSnackbar } from 'store/reducers/snackbar';
import { createNotification } from 'utils/discriminators';
import { useEndpoint } from 'hooks/useEndpoint';
import { SingleData } from 'types/single-data';
import { useSelector } from 'store';
import { NotificationType } from 'types/dto/notification.dto';
import { RightDrawer } from 'components/custom/RightDrawer';

// import { LAYOUT_CONST } from 'types/config';

// ==============================|| MAIN LAYOUT ||============================== //
const FallbackComponent = (fallbackProps: FallbackProps) => {
  const location = useLocation();
  const { sendErrorClient } = useError();
  const { resetErrorBoundary, error } = fallbackProps;
  let message: string = "C'è stato un errore nel caricamento della risorsa, si è pregati di ricaricare la pagina.";

  const pathnameRef = useRef(location.pathname);
  // const handleBackHome = () => {
  //   window.location.reload();
  // };
  useEffect(() => {
    if (location.pathname !== pathnameRef.current) {
      resetErrorBoundary();
    }
  }, [location.pathname]);

  useEffect(() => {
    sendErrorClient(error);
  }, []);

  return (
    <Box width={'100%'} position={'relative'}>
      <Container maxWidth={'lg'}>
        <Grid
          container
          width={'100%'}
          minHeight={fallbackProps.error?.cause === 'loop' || fallbackProps.error?.cause === 'server_sync' ? '100dvh' : '70dvh'}
        >
          <Grid item xs={12} md={6} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Box textAlign={'center'}>
              <Typography variant={'h6'} mb={3}>
                {fallbackProps.error?.cause === 'loop' || fallbackProps.error?.cause === 'server_sync'
                  ? fallbackProps.error.message
                  : message}
              </Typography>
              <Button onClick={() => resetErrorBoundary()} variant="contained">
                Riprova
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              img: {
                width: '100%',
                maxWidth: 300
              }
            }}
          >
            <img src={IllustrationError} alt={'error illustration'} />
          </Grid>
        </Grid>
      </Container>
      {(fallbackProps.error?.cause === 'loop' || fallbackProps.error?.cause === 'server_sync') && (
        <>
          <Box
            sx={{
              img: {
                width: '100%',
                maxWidth: { xs: 150, md: 300 },
                zIndex: -1,
                position: 'absolute',
                top: 0,
                left: 0,
                objectFit: 'contain',
                transition: 'all 1s'
              }
            }}
          >
            <img src={Rotellina1} alt={'rotellina errore'} />
          </Box>

          <Box
            sx={{
              img: {
                width: '100%',
                maxWidth: { xs: 150, md: 300 },
                zIndex: -1,
                position: 'absolute',
                bottom: 0,
                right: 0,
                objectFit: 'contain',
                transition: 'all 1s'
              }
            }}
          >
            <img src={Rotellina2} alt={'rotellina errore'} />
          </Box>
        </>
      )}
    </Box>
  );
};
const OutletWithError = withErrorBoundary(Outlet, {
  FallbackComponent
});

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const { container, miniDrawer } = useConfig();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { fetchNotification } = useSelector((state) => state.helpers);
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen, rightDrawerOpen } = menu;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);

  const getNotification = useEndpoint<SingleData<NotificationType>, 'get'>({
    method: 'get',
    endpoint: `/notifications/${fetchNotification?.id}`,
    queryKey: `get-notification-${fetchNotification?.id}`,
    options: {
      enabled: false,
      onSuccess: ({ data }) => {
        dispatch(
          openSnackbar({
            message: createNotification(data.data),

            open: true,
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            key: `info`
          })
        );
        dispatch(setFetchNotification(undefined));
      }
    }
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      document.getElementById('bootstrap-loader')?.classList.add('remove');
      setTimeout(() => {
        document.getElementById('bootstrap-loader')?.remove();
      }, 200);
    }, 1000);
  }, []);

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
  }, [matchDownLG]);

  useEffect(() => {
    if (fetchNotification && user) {
      if (fetchNotification.userId !== user.id) {
        getNotification.refetch();
      } else dispatch(setFetchNotification(undefined));
    }
  }, [fetchNotification]);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  const handleRightDrawerClose = () => {
    if (rightDrawerOpen) {
      dispatch(setRightDrawerOpen(false));
    }
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', zIndex: 2000 }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <NavDrawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <RightDrawer open={rightDrawerOpen} />
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1 }}>
        <Toolbar />

        <Container
          maxWidth={false}
          sx={{
            ...(container && { px: '0!important' }),
            position: 'relative',
            overflowY: rightDrawerOpen ? 'hidden' : 'auto',
            minHeight: 'calc(100vh - 74px)',
            display: 'flex',
            flexDirection: 'column'
          }}
          onClick={handleRightDrawerClose}
        >
          <Suspense fallback={<></>}>
            <OutletWithError />
          </Suspense>
          {/*block backdrop */}
          <Fade in={rightDrawerOpen}>
            <Box
              sx={{
                display: 'block',
                position: 'fixed',
                left: 0,
                width: '100%',
                height: 'calc(100vh - 74px)',
                zIndex: 1000,
                backgroundColor: '#041C2280'
              }}
            />
          </Fade>
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
