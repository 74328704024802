import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const ArrowDown = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0.5L5 5.5L10 0.5H0Z" fill="#041C22" fillOpacity="0.56" />
    </svg>
  </SvgIcon>
);

export default ArrowDown;
