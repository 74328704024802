import { FormattedMessage } from 'react-intl';

// assets
import Support from 'assets/images/icons/navigation/Support';

// type
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support: NavItemType = {
  id: 'support',
  title: '',
  type: 'group',
  children: [
    {
      id: 'support',
      title: <FormattedMessage id="support" />,
      type: 'item',
      icon: Support,
      url: 'https://www.jivochat.com/',
      target: true,
      external: true
    }
  ]
};

export default support;
