import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const AutopilotLogo = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg id="Livello_2" data-name="Livello 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 589.29 785.72">
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path
            fill="#094754"
            d="m589.28,785.72H126.79c-70.02,0-126.79-56.76-126.79-126.79V126.79C0,56.76,56.76,0,126.79,0h335.71c70.02,0,126.79,56.76,126.79,126.79v658.92h-.01Z"
          />
          <g>
            <polygon fill="#f0ff32" points="416.7 436.99 294.15 436.49 172.59 435.99 295.15 558.54 297.78 555.91 416.7 436.99" />
            <g>
              <circle fill="#f0ff32" cx="294.65" cy="278.96" r="36.35" />
              <path
                fill="#f0ff32"
                d="m294.65,155.64c-20.07,0-36.35,16.27-36.35,36.35s16.27,36.35,36.35,36.35,36.35-16.27,36.35-36.35-16.27-36.35-36.35-36.35Z"
              />
              <path
                fill="#1ed367"
                d="m294.65,68.66c-20.07,0-36.35,16.27-36.35,36.35s16.27,36.35,36.35,36.35,36.35-16.27,36.35-36.35-16.27-36.35-36.35-36.35Z"
              />
              <circle fill="#f0ff32" cx="294.65" cy="370.36" r="36.35" />
            </g>
          </g>
          <rect fill="#91dcf9" x="75.34" y="592.74" width="432" height="88" rx="44" ry="44" />
        </g>
      </g>
    </svg>
  </SvgIcon>
);

export default AutopilotLogo;
