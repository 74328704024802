import { useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { LinearProgressProps } from '@mui/material/LinearProgress';

export interface LoaderProps extends LinearProgressProps {}

// ==============================|| MINIMAL LAYOUT ||============================== //

const CommonLayout = () => {
  useLayoutEffect(() => {
    setTimeout(() => {
      document.getElementById('bootstrap-loader')?.classList.add('remove');
      setTimeout(() => {
        document.getElementById('bootstrap-loader')?.remove();
      }, 200);
    }, 1000);
  }, []);
  return <Outlet />;
};

export default CommonLayout;
