import { TextareaAutosize, styled } from '@mui/material';

export const StyledTextarea = styled(TextareaAutosize)(
  () => `
		width: 100%;
		font-family: 'Work Sans', sans-serif;
		font-size: 1rem;
		font-weight: 400;
		line-height: 2;
		padding-left: 12px;
		border-radius: 4px;
		resize: none;
		&:disabled {
			background-color: #FFFFFF;
			color: #000000;
		}
		// firefox
		&:focus-visible {
		  outline: 0;
		}
		`
);
