import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const FaqLogo = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.2558 12.188C24.2558 18.5387 19.1068 23.688 12.7558 23.688C6.40481 23.688 1.2561 18.5387 1.2561 12.188C1.2561 5.83729 6.40511 0.687988 12.7561 0.687988C19.1071 0.687988 24.2561 5.83699 24.2561 12.188H24.2558Z"
        fill="#094754"
      />
      <path
        d="M12.1354 19.3067C12.9781 19.3067 13.6612 18.6236 13.6612 17.7809C13.6612 16.9382 12.9781 16.2551 12.1354 16.2551C11.2927 16.2551 10.6096 16.9382 10.6096 17.7809C10.6096 18.6236 11.2927 19.3067 12.1354 19.3067Z"
        fill="#F0FF32"
      />
      <path d="M13.123 11.3706H11.1476V14.8275H13.123V11.3706Z" fill="#91DCF9" />
      <path
        d="M13.123 13.3456H11.1476V11.3702H13.123C14.2125 11.3702 15.0985 10.4842 15.0985 9.39473C15.0985 8.30523 14.2125 7.41929 13.123 7.41929H11.1476V5.44385H13.123C15.3014 5.44385 17.0736 7.21633 17.0736 9.39443C17.0736 11.5725 15.3011 13.345 13.123 13.345V13.3456Z"
        fill="#91DCF9"
      />
      <path d="M13.123 11.3706H11.1476V13.346H13.123V11.3706Z" fill="#49EBAA" />
    </svg>
  </SvgIcon>
);

export default FaqLogo;
