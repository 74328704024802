import { useEndpoint } from 'hooks/useEndpoint';

const Error = () => {
  useEndpoint<undefined, 'get'>({
    method: 'get',
    endpoint: '/errors/403',
    queryKey: `get-building-orders`
  });
  return <></>;
};

export default Error;
