import { Box } from '@mui/material';
import Header from './Header';
import { useEndpoint } from 'hooks/useEndpoint';
import { SingleData } from 'types/single-data';
import { SendTaskDTO, TaskDTO } from 'types/dto/task.dto';
import { dispatch, useSelector } from 'store';
import { useFormik } from 'formik';
import { endpointReturn } from 'utils/selectEndpoint/endpointSetter';
import { Subject } from 'types/components/subjectFilter';
import { SimpleBarStyled } from 'layout/MainLayout/Drawer/DrawerContent';
import TitleAndStatus from './TitleAndStatus';
import { openSnackbar } from 'store/reducers/snackbar';
import { ExpireDateAndPrivacy } from './ExpireDateAndPrivacy';
import { CollaboratorsAndDescription } from './CollaboratorsAndDescription';
import TaskTabs from './Tabs';
import { taskDataCheck, taskDataSelector } from 'utils/taskHelpers';
import { useEffect, useState } from 'react';
import Footer from './Footer';
import dayjs from 'dayjs';
import { setActiveRightItem } from 'store/reducers/menu';
import _ from 'lodash';
import { SingleTaskLoading } from 'components/custom/loading/SingleTaskLoading';
import { queryClient } from 'App';

interface Props {
  closeDrawer: () => void;
  subject: Subject;
  setSubject: React.Dispatch<React.SetStateAction<Subject>>;
  isNewTask?: boolean;
  newTask?: SendTaskDTO;
  sectionFilter?: string | null;
  setSectionFilter?: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}
let asyncTimeout: NodeJS.Timeout | null = null;
export const Task = ({ closeDrawer, subject, setSubject, isNewTask, sectionFilter, setSectionFilter }: Props) => {
  const { taskId, rightDrawerOpen, activeRightItem } = useSelector((state) => state.menu);
  const { newTaskTitle } = useSelector((state) => state.helpers);

  const [uploadsToSend, setUploadsToSend] = useState<File[]>([]);

  const getTask = useEndpoint<SingleData<TaskDTO>, 'get'>({
    method: 'get',
    endpoint: `/tasks/${taskId}`,
    queryKey: `get-task-${taskId}`,
    options: {
      enabled: taskId !== undefined && rightDrawerOpen,
      onSuccess: () => {
        queryClient.refetchQueries('get-tasks-readings');
      }
    }
  });
  const task = getTask.data?.data.data;

  const editTask = useEndpoint<Partial<SendTaskDTO>, 'patch'>({
    method: 'patch',
    endpoint: `/tasks/${taskId}`,
    mutationKey: `edit-task-${taskId}`,
    options: {
      onSuccess: () => {
        setUploadsToSend([]);
        dispatch(
          openSnackbar({
            message: `Task modificato con successo.`,
            open: true,
            variant: 'success',
            key: `edit-task-${task?.id}`
          })
        );
        queryClient.refetchQueries('get-all-tasks');
        queryClient.refetchQueries(`get-task-${taskId}`);
        queryClient.refetchQueries('get-tasks-readings');
      }
    }
  });

  const createTask = useEndpoint<SendTaskDTO, 'post'>({
    method: 'post',
    endpoint: '/tasks',
    mutationKey: 'create-task',
    options: {
      onSuccess: () => {
        queryClient.refetchQueries('get-all-tasks');
        queryClient.refetchQueries('get-tasks-readings');
        dispatch(
          openSnackbar({
            message: `Task creato con successo.`,
            open: true,
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            key: `create-task`
          })
        );
        dispatch(setActiveRightItem('tasks'));
      }
    }
  });

  const taskForm = useFormik<{ data: SendTaskDTO | undefined }>({
    enableReinitialize: activeRightItem !== 'new-task',
    initialValues: {
      data: isNewTask
        ? {
            title: newTaskTitle,
            public: false,
            resource: sectionFilter === 'all' ? null : sectionFilter,
            subject: subject.serverData,
            description: '',
            state: 'created',
            comments: [],
            expireAt: dayjs().add(7, 'days').toISOString(),
            todos: [],
            subscriptions: [],
            uploads: undefined
          }
        : task
        ? {
            id: task.id,
            title: task.title,
            public: task.public,
            //@ts-ignore
            resource: task?.resource?.slug,
            subject: task.subject,
            description: task.description,
            state: task.state.key,
            comments: task.comments,
            expireAt: task.expireAt,
            todos: task.todos,
            subscriptions: task.subscriptions,
            lastEdit: task.lastEdit,
            uploads: task.uploads
          }
        : undefined
    },
    onSubmit: (values) => {
      if (isNewTask && values.data) {
        createTask.mutate(values.data);
        return;
      }
      handleEditTask();
    }
  });

  const init = taskForm.initialValues.data;
  const val = taskForm.values.data;

  const handleEditTask = () => {
    if (asyncTimeout) {
      clearTimeout(asyncTimeout);
      asyncTimeout = null;
    }
    if (init && val) {
      if (taskDataCheck(init, val, 'async')) {
        // socket.emit('updateTask', { id: taskId });
        const timeout = setTimeout(() => {
          editTask.mutate(taskDataSelector(init, val, init.lastEdit!));
        }, 3000);
        asyncTimeout = timeout;
      } else if (taskDataCheck(init, val, 'instant')) {
        editTask.mutate(taskDataSelector(init, val, init.lastEdit!));
      }
    }
  };

  useEffect(() => {
    if (!isNewTask) {
      handleEditTask();
    }
  }, [taskForm.values.data]);

  useEffect(() => {
    if (uploadsToSend.length) {
      editTask.mutate({ uploads: uploadsToSend });
    }
  }, [uploadsToSend]);

  const endpoint = endpointReturn(taskForm.values.data?.resource);
  const disabled = getTask.isLoading || editTask.isLoading;
  const buttonDisabled = !_.isEqual(init, val);

  if (!taskForm.values.data) {
    // if (true) {
    return (
      <Box className="standard-margin-container">
        <SingleTaskLoading />
      </Box>
    );
  }

  return (
    <Box className="right-drawer-el-container">
      <Header
        endpoint={endpoint}
        closeDrawer={closeDrawer}
        formikValues={taskForm.values.data}
        setFormikValues={taskForm.setFieldValue}
        subject={subject}
        setSubject={setSubject}
        sectionFilter={taskForm.values.data.resource}
        setSectionFilter={setSectionFilter}
        isNewTask={isNewTask ?? false}
      />
      <Box className="standard-margin-container">
        <SimpleBarStyled
          sx={{
            '& .simplebar-content': {
              display: 'flex',
              flexDirection: 'column'
            },
            width: '103%',
            paddingRight: '3%',
            height: 'calc(100vh - 236px)',
            position: 'relative'
          }}
        >
          <TitleAndStatus values={taskForm.values.data} setValues={taskForm.setFieldValue} canEdit={disabled} />
          <ExpireDateAndPrivacy taskForm={taskForm.values.data} setValues={taskForm.setFieldValue} disabled={disabled} />
          <CollaboratorsAndDescription taskForm={taskForm.values.data} setValues={taskForm.setFieldValue} disabled={disabled} />
          <TaskTabs isNewTask={isNewTask ?? false} taskForm={taskForm.values.data} setValues={taskForm.setFieldValue} disabled={disabled} />
        </SimpleBarStyled>
      </Box>
      <Footer disabled={buttonDisabled} taskForm={taskForm.values.data} isNewTask={isNewTask ?? false} submit={taskForm.handleSubmit} />
    </Box>
  );
};
