import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AnimationBox } from './AnimationBox';
import { formatBackOrder } from 'utils/formattation';

const Buildings = ({ path }: { path: string[] }) => {
  let buildings: JSX.Element = <></>;
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  switch (path.length) {
    case 4:
      buildings = (
        <Box className="flex ali-center" flexGrow={1}>
          <Typography variant="h5" component={Link} to={'/buildings'} sx={{ color: 'primary.main', textDecoration: 'none' }}>
            Strutture
          </Typography>
          <KeyboardArrowRight sx={{ marginX: 1 }} />
          <Typography
            variant="h5"
            component={Link}
            maxWidth={downLG ? 130 : 320}
            noWrap
            to={`/buildings/${path[path.length - 3]}/${path[path.length - 2]}`}
            sx={{ color: 'primary.main', textDecoration: 'none' }}
          >
            {path && formatBackOrder(path[path.length - 2])}
          </Typography>
          <AnimationBox className="flex ali-center">
            <KeyboardArrowRight sx={{ marginX: 1 }} />
            <Typography variant="h5" fontWeight={700} maxWidth={downLG ? 130 : 320} noWrap>
              <FormattedMessage id={path[path.length - 1]} />
            </Typography>
          </AnimationBox>
        </Box>
      );
      break;
    case 3:
      buildings = (
        <Box className="flex ali-center">
          <Typography variant="h5" component={Link} to={'/buildings'} sx={{ color: 'primary.main', textDecoration: 'none' }}>
            Strutture
          </Typography>
          <AnimationBox className="flex ali-center">
            <KeyboardArrowRight sx={{ marginX: 1 }} />
            <Typography variant="h5" fontWeight={700}>
              {path && formatBackOrder(path[path.length - 1])}
            </Typography>
          </AnimationBox>
        </Box>
      );
      break;
    default:
      buildings = <Typography variant="h5">Strutture</Typography>;
      break;
  }

  return (
    <Box className="flex ali-center" flexGrow={1}>
      {buildings}
    </Box>
  );
};
export default Buildings;
