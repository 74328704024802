// material-ui
import { Theme, TypographyVariantsOptions } from '@mui/material/styles';

// types
import { FontFamily, ThemeMode } from 'types/config';

// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = (mode: ThemeMode, fontFamily: FontFamily, theme: Theme): TypographyVariantsOptions => ({
  htmlFontSize: 16,
  fontFamily,
  // fontWeightLight: 300,
  // fontWeightRegular: 400,
  // fontWeightMedium: 500,
  // fontWeightBold: 600,
  h1: {
    letterSpacing: -1.5,
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '6rem',
    lineHeight: 1.167
  },
  h2: {
    letterSpacing: -0.5,
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '3.750rem',
    lineHeight: 1.1
  },
  h3: {
    letterSpacing: -1,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '3rem',
    lineHeight: 1.167
  },
  h4: {
    letterSpacing: -0.5,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '2.125rem',
    lineHeight: 1.235
  },
  h5: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: 1.334
  },
  h6: {
    letterSpacing: -0.15,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.250rem',
    lineHeight: 1.3
  },
  caption: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66
  },
  body1: {
    letterSpacing: -0.3,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.3
  },
  body2: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43
  },
  subtitle1: {
    letterSpacing: 0.15,
    fontStyle: 'normal',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: 1.35
  },
  subtitle2: {
    letterSpacing: 0.1,
    fontStyle: 'normal',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.37
  },
  overline: {
    letterSpacing: -1,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.750rem',
    lineHeight: 2.66
  },
  button: {
    textTransform: 'uppercase'
  }
});

export default Typography;
