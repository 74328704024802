import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const ArrowBack = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.77875 1.5575L4.72125 0.5L0.221252 5L4.72125 9.5L5.77875 8.4425L2.34375 5L5.77875 1.5575Z"
        fill="#9aa4a7"
        fillOpacity="0.87"
      />
    </svg>
  </SvgIcon>
);

export default ArrowBack;
