import Navigation from './Navigation';
import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import SimpleBar from 'simplebar-react';

// ==============================|| DRAWER CONTENT ||============================== //
export const SimpleBarStyled = styled(SimpleBar)(() => ({
  '& .simplebar-content': {
    height: '100%'
  }
}));
const DrawerContent = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <SimpleBarStyled
      style={{
        height: !matchDownSM ? 'calc(100% - 80px)' : 'calc(100% - 60px)',
        borderRight: `1px solid ${theme.palette.divider}`
      }}
    >
      <Navigation />
    </SimpleBarStyled>
  );
};

export default DrawerContent;
