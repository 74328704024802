import { Box, Pagination } from '@mui/material';
import Header from './Header';
import { Subject } from 'types/components/subjectFilter';
import { NoteAndSearch } from './NoteAndSearch';
import NoNotes from './NoNotes';
import NotesLoading from 'components/custom/loading/NotesLoading';
import { CountedData } from 'types/counted-data';
import { NoteDTO } from 'types/dto/note.dto';
import NoteList from './NoteList';
import { PaginationType } from 'types/custom/pagination';
import { Section } from 'types/components/sectionFilter';
import useLoader from 'hooks/useLoader';

interface Props {
  closeDrawer: () => void;
  notes: CountedData<NoteDTO> | undefined;
  subject: Subject;
  setSubject: React.Dispatch<React.SetStateAction<Subject>>;
  resource: Section;
  setResource: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  pageSize: number;
  setPagination: React.Dispatch<React.SetStateAction<PaginationType>>;
  setSearchFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  isFetching: boolean;
}
export const Note = ({
  closeDrawer,
  subject,
  setSubject,
  resource,
  setResource,
  pageSize,
  setPagination,
  notes,
  setSearchFilter,
  isFetching
}: Props) => {
  const { isLoading } = useLoader();

  return (
    <Box className="right-drawer-el-container">
      <Header onClose={closeDrawer} resource={resource} setResource={setResource} subject={subject} setSubject={setSubject} />
      <Box className="standard-margin-container">
        <NoteAndSearch subject={subject} section={resource} setSearchFilter={setSearchFilter} />
        {notes && notes.data.length ? (
          <Box
            className={`${
              subject.label.length ? 'edited-notes-height' : 'standard-notes-height'
            } vertical-standard-margin flex dir-col just-btwn`}
          >
            {isLoading ? <NotesLoading pageSize={pageSize} /> : <NoteList notes={notes.data} />}

            <Pagination
              onChange={(e, v) => setPagination({ pageIndex: v - 1, pageSize: 6 })}
              count={notes.filteredCount && Math.ceil(notes.filteredCount / 6)}
              size="large"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
                pb: 2,
                '.MuiPaginationItem-root': { position: 'relative', zIndex: 1 }
              }}
            />
          </Box>
        ) : notes && notes.data.length === 0 ? (
          <NoNotes />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
