import { Box, useMediaQuery, useTheme } from '@mui/material';

type EmbedFileProps = {
  fileCode?: string;
  type?: string;
  history?: boolean;
  local?: boolean;
};
const EmbedFile = ({ fileCode, type, history = false, local }: EmbedFileProps) => {
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));

  if (local) {
    return (
      <Box sx={{ minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={fileCode} alt={'local-file'} />
      </Box>
    );
  }
  if (type === 'image/png' || type === 'image/jpeg') {
    return (
      <Box sx={{ minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={`https://server-dev.buyplus.biz/server/v1/uploads/${history ? 'file/' : ''}${fileCode}`} alt={`upload-${fileCode}`} />
      </Box>
    );
  }
  return (
    <embed
      src={`https://server-dev.buyplus.biz/server/v1/uploads/${history ? 'file/' : ''}${fileCode}`}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 3,
        width: '100%',
        minHeight: isXS ? '90%' : isXL ? '700px' : '500px',
        height: '100%',
        overflow: 'hidden'
      }}
    />
  );
};

export default EmbedFile;
