// material-ui
import { Box } from '@mui/material';

// project import
import MiniDrawerStyled from './MiniDrawerStyled';
import { DrawerContent } from './content';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

interface Props {
  open: boolean;
}

export const RightDrawer = ({ open }: Props) => {
  return (
    <Box component="nav" sx={{ zIndex: 1500, width: 0 }} aria-label="mailbox folders">
      <MiniDrawerStyled
        ModalProps={{ keepMounted: false }}
        variant="permanent"
        open={open}
        anchor="right"
        sx={{ '.MuiDrawer-paperAnchorRight': { boxShadow: open ? '0 6px 20px 5px rgba(0,0,0,0.2)' : 'none' } }}
      >
        <DrawerContent />
      </MiniDrawerStyled>
    </Box>
  );
};
