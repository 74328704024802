import { WebsocketContext } from 'components/custom/Websocket';
import { useContext } from 'react';

const useWebsocket = () => {
  const context = useContext(WebsocketContext);

  if (!context) throw new Error('context must be use inside provider');

  return context;
};

export default useWebsocket;
