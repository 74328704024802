import { Box, Menu, Typography } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { translateCategory } from 'utils/translatePath';
import { VariableSelect } from './VariableSelect';

interface Props {
  core: any;
}

// interface FakeData {
//   user: Partial<UserDTO>;
//   building: Partial<BuildingDTO>;
//   order: Partial<OrderDTO>;
// }

interface Categories {
  user?: CustomVariable[];
  building?: CustomVariable[];
  wallet?: CustomVariable[];
  order?: CustomVariable[];
}
interface CustomVariable {
  serverProp: string;
  label: string;
}
const fakeData: Categories = {
  user: [
    { serverProp: 'user.$.firstname', label: 'Lista utenti | Nome' },
    { serverProp: 'user.$0.firstname', label: 'Utente | Nome' },
    { serverProp: 'user.$0.lastname', label: 'Utente | Cognome' },
    { serverProp: 'user.$0.phone', label: 'Utente | N. telefono' }
  ],
  building: [
    { serverProp: `user.$0.buildings.$0.name`, label: 'Struttura | Nome' },
    { serverProp: 'user.$0.buildings.$0.businessName', label: 'Struttura | Nome società' }
  ],
  order: [
    { serverProp: 'order.$0.code', label: 'Ordine | Codice' },
    { serverProp: 'order.$0.title', label: 'Ordine | Titolo' }
  ]
};

export const VariablesPlugin = ({ core }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // }
  const handleAddVariable = ({ serverProp, label }: { serverProp: string; label: string }, category: string) => {
    if (label.includes('Lista')) {
      core.functions.insertHTML(
        `<div>
          <div>
            <span><!--auto-list=${category}--></span>
            <br class="__se__tag hide"/>
            <span><!--auto-element=${serverProp}--></span>
            <span class='se-custom-variable'>${label}</span>
            <span><!--/auto-element--></span>
            <br class="__se__tag hide"/>
            <span><!--/auto-list--></span>
          </div>
        </div>
    `
      );
      console.log(core.detachList(['<li>'], true));
      core.detachList(['LI'], true);
      return;
    }
    core.functions.insertHTML(
      `<span><!--auto-element=${serverProp}--></span><span class='se-custom-variable'>${label}</span><span><!--/auto-element--></span>`
    );
    // const node = core.util.createElement('span');
    // const space = core.util.createElement('span');
    // space.textContent = '\xa0';
    // // core.util.addClass(node, `se-custom-variable __autopilot-key={${serverProp}__`);
    // node.textContent = label;
    // core.functions.insertHTML(``);
    // core.insertNode(node);
    // core.insertNode(space, node);
    // core.functions.insertHTML('');
  };

  return (
    <>
      {/* <div>
        <div>
          <span><!--auto-list=${category}--></span>
        <br/>
        <span><!--auto-element=${serverProp}--></span>
        <span class='se-custom-variable'>${label}</span>
        <span><!--/auto-element--></span>
      <br/>
        <span><!--/auto-list--></span>
      </div>
    </div> */}
      <Box
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: 5,
          borderRadius: 1,
          paddingX: 2,
          border: '1px solid #DADADA',
          backgroundColor: '#fafafa',
          left: 626,
          height: '38px',
          zIndex: 3000,
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'rgb(240,240,240)'
          }
        }}
      >
        <Typography variant="caption" sx={{ color: '#000000', fontFamily: 'arial', fontSize: '12px' }}>
          {'Inserisci variabile'}
        </Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          zIndex: 3000,
          marginLeft: -40,
          '.MuiPaper-root': {
            background: 'white',
            minWidth: '200px',
            paddingX: 4,
            boxShadow: '-1px 2px 2px rgba(0,0,0,.2)'
          }
        }}
      >
        <Box width="100%" className="flex just-btwn">
          {Object.entries(fakeData).map((cat, i) => (
            <Box key={`category-${i}`} width={200} mx={1} mb={3}>
              <Typography>{translateCategory(cat[0])}</Typography>
              <VariableSelect
                type={cat[0] as 'user' | 'building' | 'order' | 'wallet'}
                options={cat[1] as CustomVariable[]}
                addVariable={(newVar) => {
                  handleAddVariable(newVar, cat[0]);
                }}
              />
              {/* <VariableSearch endpoint={cat} addVariable={(newVar: { serverProp: string; label: string }) => handleAddVariable(newVar)} /> */}
            </Box>
          ))}
        </Box>
        {/* <Button
          onClick={() => {
            handleAddVariable({ serverProp: 'user.firstname', label: 'Utente | Nome' });
          }}
        >
          aggiungi variabile nome utente
        </Button>
        <Button
          onClick={() => {
            handleAddVariable({ serverProp: 'user.lastname', label: 'Utente | Cognome' });
          }}
        >
          aggiungi variabile cognome utente
        </Button>
        <Button
          onClick={() => {
            handleAddVariable({ serverProp: 'order.code', label: 'Ordine | Codice identificativo' });
          }}
        >
          aggiungi variabile codice ordine
        </Button> */}
      </Menu>
    </>
  );
};
